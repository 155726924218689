<template>
    <auth-layout>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 mt-3 vh-100">
                    <loading-indicator :title="userMessage" />
                </div>
            </div>
        </div>
    </auth-layout>
</template>

<script>
    import Auth from '@/layouts/Auth'
    import { i18n } from '@/utils/i18n'
    import { isEmployerEmailVerified } from '@/services/auth'
    import { getNextRoute } from '@/flow/flowController'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { logger } from '@/utils/logger'

    export default {
        components: {
            'auth-layout': Auth,
            LoadingIndicator,
        },
        data() {
            return {
                userMessage: i18n.t('pages.auth.employerEmailPurgatory.title'),
                hasValidated: false,
            }
        },
        mounted() {
            this.$logEvent('view_employer_email_purgatory')
            const intervalToken = setInterval(async () => {
                try {
                    const resp = await isEmployerEmailVerified()

                    if (resp?.data?.success && !this.hasValidated) {
                        this.hasValidated = true
                        logger.log('Email link was verified!')
                        // Don't forget to do this, unless you want infinite API function calls
                        clearInterval(intervalToken)
                        appSessionStorage.setItem(localStorageKey.employerEmailVerificationCompleted, 'true')
                        return await this.$router.push(getNextRoute(this.$router))
                    }

                    logger.log('Email not yet verified!')
                } catch (error) {
                    // Don't forget to do this, unless you want infinite API function calls
                    clearInterval(intervalToken)
                    ApiErrorHandler(error)
                }
            }, 5000)
        },
    }
</script>
