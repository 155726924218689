import Vue from 'vue'
import { logger } from '@/utils/logger'
import { getSoftPullResults, initiateSoftPull } from '@/services/loanApplication'

export enum SoftPullResult {
    success = 'success',
    fail = 'fail',
    experianFrozen = 'experianFrozen',
    experianNotFound = 'experianNotFound',
    isIneligible = 'isIneligible',
}

export default Vue.extend({
    data: function () {
        return {
            loanApplicationDate: new Date(),
            dti: null,
            score: null,
        }
    },
    methods: {
        softPullApplicant: async function () {
            const initiationResp = await initiateSoftPull()
            const createdAfter = initiationResp.data.payload.createdAfter

            let resp
            for (let i = 0; i < 20; i++) {
                // poll for 20 * 2.5 seconds = 50 seconds (+change from each request)
                resp = await getSoftPullResults(createdAfter)
                if (resp.data.success) {
                    logger.info('Soft pull fetch completed!')
                    break
                }
                if (!resp.data.success && resp.data.payload?.isMissingSSN) {
                    logger.info('Missing SSN, not going to continue trying fetching experian data...')
                    break
                }
                logger.info(`Still waiting for soft pull data fetch. Attempt nr ${i + 1}`)
                await new Promise((r) => setTimeout(r, 2500))
            }
            if (!resp?.data?.success) {
                return SoftPullResult.fail
            }
            if (resp.data.payload.isRecordNotFound) {
                return SoftPullResult.experianNotFound
            }
            if (resp.data.payload.isIneligible) {
                return SoftPullResult.isIneligible
            }
            if (resp.data.payload.isFrozen) {
                return SoftPullResult.experianFrozen
            }
            return SoftPullResult.success
        },
    },
})
