<template>
    <li class="g-0 row mb-2">
        <div class="col text-start">
            <p
                v-if="isLowRate"
                class="fw-bold mb-0 mt-1"
            >
                {{ $t('components.onboarding.rateComparisonGraphListItem.lowRateTitle', { rate: rateFormatted }) }}
            </p>
            <p
                v-else
                class="fw-bold mb-0 mt-1"
            >
                {{ $t('components.onboarding.rateComparisonGraphListItem.title') }}
            </p>

            <div
                v-if="isLowRate"
                class="text-muted"
            >
                {{ $t('components.onboarding.rateComparisonGraphListItem.lowRateContent') }}
            </div>
            <div
                v-else
                class="text-muted"
            >
                {{ $t('components.onboarding.rateComparisonGraphListItem.content') }}
            </div>
            <svg
                class="pt-2"
                viewBox="0 0 325 203"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                alt="Rate Comparison Graph"
            >
                <rect
                    width="325"
                    height="203"
                    rx="16"
                    fill="white"
                />
                <line
                    x1="25"
                    :y1="24 + compairsonBarHeight"
                    x2="300"
                    :y2="24 + compairsonBarHeight"
                    stroke="lightgray"
                />
                <line
                    x1="25"
                    :y1="compairsonBarHeight"
                    x2="300"
                    :y2="compairsonBarHeight"
                    stroke="lightgray"
                />
                <line
                    x1="25"
                    :y1="compairsonBarHeight - 24"
                    x2="300"
                    :y2="compairsonBarHeight - 24"
                    stroke="lightgray"
                />
                <line
                    x1="25"
                    :y1="compairsonBarHeight - 48"
                    x2="300"
                    :y2="compairsonBarHeight - 48"
                    stroke="lightgray"
                />
                <line
                    x1="25"
                    :y1="compairsonBarHeight - 72"
                    x2="300"
                    :y2="compairsonBarHeight - 72"
                    stroke="lightgray"
                />
                <text
                    x="77"
                    y="156"
                    fill="black"
                    font-weight="bold"
                    font-size="0.76em"
                >Your Aven</text>
                <text
                    x="94"
                    y="174"
                    fill="black"
                    font-weight="bold"
                    font-size="0.76em"
                >Rate</text>
                <defs>
                    <clipPath id="round-corner">
                        <rect
                            x="80"
                            :y="24 + rateBarOffset + 10"
                            width="56"
                            :height="rateBarHeight"
                            rx="5"
                            ry="5"
                            fill="#4A9F6E"
                        />
                    </clipPath>
                </defs>
                <rect
                    x="80"
                    :y="24 + rateBarOffset"
                    width="56"
                    :height="rateBarHeight"
                    fill="#4A9F6E"
                    clip-path="url(#round-corner)"
                />
                <text
                    x="108"
                    :y="25 + rateBarTextOffset"
                    text-anchor="middle"
                    dominant-baseline="middle"
                    fill="#ffffff"
                    font-weight="bold"
                    font-size="0.76em"
                >{{ rateFormatted }}%</text>
                <defs>
                    <clipPath id="round-corner-comparison">
                        <rect
                            x="189"
                            y="24"
                            width="56"
                            :height="compairsonBarHeight + 10"
                            rx="5"
                            ry="5"
                            fill="#4A9F6E"
                        />
                    </clipPath>
                </defs>
                <rect
                    x="189"
                    y="24"
                    width="56"
                    :height="compairsonBarHeight"
                    fill="#D8DCDF"
                    clip-path="url(#round-corner-comparison)"
                />
                <g v-if="isLowRate">
                    <text
                        x="202"
                        y="79"
                        fill="#6E7A82"
                        font-weight="bold"
                        font-size="0.76em"
                    >{{ lowRateComparison }}</text>
                    <text
                        x="180"
                        y="156"
                        fill="#6E7A82"
                        font-size="0.76em"
                    >Other HELOC</text>
                    <text
                        x="195"
                        y="174"
                        fill="#6E7A82"
                        font-size="0.76em"
                    >Lenders</text>
                </g>
                <g v-else>
                    <text
                        x="197"
                        y="79"
                        fill="#6E7A82"
                        font-weight="bold"
                        font-size="0.76em"
                    >{{ scoreRateComparison }}</text>
                    <text
                        x="173"
                        y="156"
                        fill="#6E7A82"
                        font-size="0.76em"
                    >Online Personal</text>
                    <text
                        x="203"
                        y="174"
                        fill="#6E7A82"
                        font-size="0.76em"
                    >Loan</text>
                </g>
            </svg>
            <div
                v-if="isLowRate"
                class="text-muted small pt-1"
                v-html="this.$t('components.onboarding.rateComparisonGraphListItem.lowRateSource')"
            />
            <div
                v-else
                class="text-muted small pt-1"
                v-html="this.$t('components.onboarding.rateComparisonGraphListItem.source')"
            />
        </div>
    </li>
</template>

<script>
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    const LOW_RATE_COMPARISON_RATE = 0.07
    const COMPARISON_RATE = 0.15
    const COMPARISON_BAR_HEIGHT = 102
    const COMPARISON_RATE_MODIFIER = 0.1
    export default {
        name: 'RateComparisonGraphListItem',
        props: {
            rate: { type: Number, required: true },
            dti: { type: Number, required: true },
            score: { type: Number, required: true },
        },
        data: function () {
            return {
                compairsonBarHeight: COMPARISON_BAR_HEIGHT,
            }
        },
        mixins: [experimentsMixin],
        computed: {
            isLowRate: function () {
                return this.rate < this.heraclesParameter.PRE_QUALIFICATION_LOW_APR_LIMIT
            },
            lowRateComparison: function () {
                // modify the users dti, add 10% and return the average competitors rate
                // will only display when isLowRate = true
                const modifiedDTI = this.dti + COMPARISON_RATE_MODIFIER
                if (modifiedDTI < 0.36) {
                    return '7.04%'
                } else if (modifiedDTI > 0.36 && modifiedDTI < 0.43) {
                    return '7.05%'
                }
                return '7.06%'
            },
            scoreRateComparison: function () {
                // take the users credit score and return the average competitors rate
                // will only display when isLowRate = false
                const score = this.score
                if (score > 760) {
                    return '9.30%'
                } else if (score < 760 && score > 720) {
                    return '13.32%'
                } else if (score < 720 && score > 660) {
                    return '17.82%'
                }
                return '22.16%'
            },
            rateFormatted: function () {
                return (this.rate * 100).toFixed(2)
            },
            rateBarHeight: function () {
                return (this.rate / (this.isLowRate ? LOW_RATE_COMPARISON_RATE : COMPARISON_RATE)) * COMPARISON_BAR_HEIGHT
            },
            rateBarOffset: function () {
                return COMPARISON_BAR_HEIGHT - this.rateBarHeight
            },
            rateBarTextOffset: function () {
                // Center the user's rate within the rate bar
                return this.rateBarOffset + this.rateBarHeight * 0.5
            },
        },
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';
</style>
