<template>
    <onboarding-layout
        :title="$t('pages.origination.hmda.title')"
        :loading="loading"
        :loading-title="$t('pages.origination.hmda.loading')"
        :sub-title="$t('pages.origination.hmda.skip')"
        @sub-title-click="this.skipAll"
        :error-text="errorText"
    >
        <form-container id="hmdaForm">
            <!-- Sex -->
            <div id="sex">
                <p class="fw-bold">
                    {{ $t('pages.origination.hmda.sex.placeholder') }}
                </p>
                <div class="custom-control custom-radio">
                    <input
                        class="custom-control-input"
                        type="radio"
                        name="sex"
                        value="female"
                        id="femaleSex"
                        v-model="sex"
                    >
                    <label
                        class="custom-control-label"
                        for="femaleSex"
                    >{{ $t('pages.origination.hmda.sex.female') }} </label>
                </div>
                <div class="custom-control custom-radio">
                    <input
                        class="custom-control-input"
                        type="radio"
                        name="sex"
                        value="male"
                        id="maleSex"
                        v-model="sex"
                    >
                    <label
                        for="maleSex"
                        class="custom-control-label"
                    >
                        {{ $t('pages.origination.hmda.sex.male') }}
                    </label>
                </div>
                <div class="custom-control custom-radio">
                    <input
                        class="custom-control-input"
                        type="radio"
                        name="sex"
                        value="none"
                        id="noSex"
                        v-model="sex"
                    >
                    <label
                        for="noSex"
                        class="custom-control-label"
                    >{{ $t('pages.origination.hmda.dontProvide') }}</label>
                </div>
            </div>

            <!-- Ethnicity -->
            <div
                id="ethnicity"
                class="mt-4"
            >
                <p class="fw-bold">
                    {{ $t('pages.origination.hmda.ethnicity.placeholder') }}
                </p>
                <div class="custom-control custom-radio">
                    <input
                        class="custom-control-input"
                        type="radio"
                        name="ethnicity"
                        value="hispanic"
                        id="hispanicPlaceholder"
                        v-model="rawEthnicity"
                    >
                    <label
                        for="hispanicPlaceholder"
                        class="custom-control-label"
                    >{{ $t('pages.origination.hmda.ethnicity.hispanic.placeholder') }}</label>
                    <div class="subRadio">
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="hispanicEthnicity"
                                value="mexican"
                                id="mexicanEthnicity"
                                v-model="rawHispanicEthnicity"
                            >
                            <label
                                for="mexicanEthnicity"
                                class="custom-control-label"
                            >
                                {{ $t('pages.origination.hmda.ethnicity.hispanic.mexican') }}
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="hispanicEthnicity"
                                value="puertoRican"
                                id="puertoRicanEthnicity"
                                v-model="rawHispanicEthnicity"
                            >
                            <label
                                for="puertoRicanEthnicity"
                                class="custom-control-label"
                            >
                                {{ $t('pages.origination.hmda.ethnicity.hispanic.puertoRican') }}
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="hispanicEthnicity"
                                value="cuban"
                                id="cubanEthnicity"
                                v-model="rawHispanicEthnicity"
                            >
                            <label
                                for="cubanEthnicity"
                                class="custom-control-label"
                            >{{ $t('pages.origination.hmda.ethnicity.hispanic.cuban') }}</label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="hispanicEthnicity"
                                value="otherHispanicEthnicity"
                                id="otherHispanicEthnicityPlaceholder"
                                v-model="rawHispanicEthnicity"
                            >
                            <label
                                for="otherHispanicEthnicityPlaceholder"
                                class="custom-control-label"
                            >
                                {{ $t('pages.origination.hmda.ethnicity.hispanic.other.placeholder') }}
                            </label>
                            <div class="subRadio">
                                <form-field
                                    v-model="otherHispanicEthnicity"
                                    class="form-field mb-2"
                                    validation-mode="passive"
                                    validation-rules="min:2"
                                    name="otherHispanicEthnicity"
                                    :placeholder="$t('pages.origination.hmda.ethnicity.hispanic.other.hint')"
                                    :label="$t('pages.origination.hmda.ethnicity.hispanic.other.hint')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custom-control custom-radio">
                    <input
                        class="custom-control-input"
                        type="radio"
                        name="ethnicity"
                        value="notHispanic"
                        id="notHispanicEthnicity"
                        v-model="rawEthnicity"
                    >
                    <label
                        for="notHispanicEthnicity"
                        class="custom-control-label"
                    >
                        {{ $t('pages.origination.hmda.ethnicity.notHispanic') }}
                    </label>
                </div>
                <div class="custom-control custom-radio">
                    <input
                        class="custom-control-input"
                        type="radio"
                        name="ethnicity"
                        value="none"
                        id="noEthnicity"
                        v-model="rawEthnicity"
                    >
                    <label
                        for="noEthnicity"
                        class="custom-control-label"
                    >
                        {{ $t('pages.origination.hmda.dontProvide') }}
                    </label>
                </div>
            </div>

            <!-- Race -->
            <div
                id="race"
                class="mt-4"
            >
                <p class="fw-bold">
                    {{ $t('pages.origination.hmda.race.placeholder') }}
                </p>
                <!-- American Indian -->
                <div class="custom-control custom-radio">
                    <input
                        class="custom-control-input"
                        type="radio"
                        name="race"
                        value="americanIndianRace"
                        id="americanIndianRacePlaceholder"
                        v-model="rawRace"
                    >
                    <label
                        for="americanIndianRacePlaceholder"
                        class="custom-control-label"
                    >
                        {{ $t('pages.origination.hmda.race.americanIndian.placeholder') }}
                    </label>
                    <div class="subRadio">
                        <form-field
                            v-model="otherAmericanIndianRace"
                            class="form-field mb-2"
                            validation-mode="passive"
                            validation-rules="min:2"
                            name="americanIndianRace"
                            :placeholder="$t('pages.origination.hmda.race.americanIndian.hint')"
                            :label="$t('pages.origination.hmda.race.americanIndian.hint')"
                        />
                    </div>
                </div>
                <!-- Asian -->
                <div class="custom-control custom-radio">
                    <input
                        class="custom-control-input"
                        type="radio"
                        name="race"
                        id="asianRacePlaceholder"
                        value="asian"
                        v-model="rawRace"
                    >
                    <label
                        for="asianRacePlaceholder"
                        class="custom-control-label"
                    >
                        {{ $t('pages.origination.hmda.race.asian.placeholder') }}
                    </label>
                    <div class="subRadio">
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="asianRace"
                                value="asianIndian"
                                id="asianIndianRace"
                                v-model="rawAsianRace"
                            >
                            <label
                                for="asianIndianRace"
                                class="custom-control-label"
                            >
                                {{ $t('pages.origination.hmda.race.asian.asianIndian') }}
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="asianRace"
                                value="chinese"
                                id="chineseRace"
                                v-model="rawAsianRace"
                            >
                            <label
                                for="chineseRace"
                                class="custom-control-label"
                            >
                                {{ $t('pages.origination.hmda.race.asian.chinese') }}
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="asianRace"
                                value="filipino"
                                id="filipinoRace"
                                v-model="rawAsianRace"
                            >
                            <label
                                for="filipinoRace"
                                class="custom-control-label"
                            >
                                {{ $t('pages.origination.hmda.race.asian.filipino') }}
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="asianRace"
                                value="japanese"
                                id="japaneseRace"
                                v-model="rawAsianRace"
                            >
                            <label
                                for="japaneseRace"
                                class="custom-control-label"
                            >
                                {{ $t('pages.origination.hmda.race.asian.japanese') }}
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="asianRace"
                                value="korean"
                                id="koreanRace"
                                v-model="rawAsianRace"
                            >
                            <label
                                for="koreanRace"
                                class="custom-control-label"
                            >
                                {{ $t('pages.origination.hmda.race.asian.korean') }}
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="asianRace"
                                value="vietnamese"
                                id="vietnameseRace"
                                v-model="rawAsianRace"
                            >
                            <label
                                for="vietnameseRace"
                                class="custom-control-label"
                            >
                                {{ $t('pages.origination.hmda.race.asian.vietnamese') }}
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="asianRace"
                                value="otherAsianRace"
                                id="otherAsianRacePlaceholder"
                                v-model="rawAsianRace"
                            >
                            <label
                                for="otherAsianRacePlaceholder"
                                class="custom-control-label"
                            >
                                {{ $t('pages.origination.hmda.race.asian.other.placeholder') }}
                            </label>
                            <div class="subRadio">
                                <form-field
                                    v-model="otherAsianRace"
                                    class="form-field mb-2"
                                    validation-mode="passive"
                                    validation-rules="min:2"
                                    name="otherAsianRace"
                                    :placeholder="$t('pages.origination.hmda.race.asian.other.hint')"
                                    :label="$t('pages.origination.hmda.race.asian.other.hint')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Black -->
                <div class="custom-control custom-radio">
                    <input
                        class="custom-control-input"
                        type="radio"
                        name="race"
                        value="black"
                        id="blackRace"
                        v-model="rawRace"
                    >
                    <label
                        for="blackRace"
                        class="custom-control-label"
                    >
                        {{ $t('pages.origination.hmda.race.black') }}
                    </label>
                </div>
                <!-- hawaiian -->
                <div class="custom-control custom-radio">
                    <input
                        class="custom-control-input"
                        type="radio"
                        name="race"
                        value="hawaiian"
                        id="hawiianRacePlaceholder"
                        v-model="rawRace"
                    >
                    <label
                        for="hawiianRacePlaceholder"
                        class="custom-control-label"
                    >
                        {{ $t('pages.origination.hmda.race.hawaiian.placeholder') }}
                    </label>
                    <div class="subRadio">
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="hawiianRace"
                                value="nativeHawaiian"
                                id="nativeHawaiianRace"
                                v-model="rawHawaiianRace"
                            >
                            <label
                                for="nativeHawaiianRace"
                                class="custom-control-label"
                            >
                                {{ $t('pages.origination.hmda.race.hawaiian.native') }}
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="hawiianRace"
                                value="guamanian"
                                id="guamanianRace"
                                v-model="rawHawaiianRace"
                            >
                            <label
                                for="guamanianRace"
                                class="custom-control-label"
                            >
                                {{ $t('pages.origination.hmda.race.hawaiian.guamanian') }}
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="hawiianRace"
                                value="samoan"
                                id="samoanRace"
                                v-model="rawHawaiianRace"
                            >
                            <label
                                for="samoanRace"
                                class="custom-control-label"
                            >
                                {{ $t('pages.origination.hmda.race.hawaiian.samoan') }}
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="hawiianRace"
                                value="otherHawaiianRace"
                                id="otherHawaiianRace"
                                v-model="rawHawaiianRace"
                            >
                            <label
                                for="otherHawaiianRace"
                                class="custom-control-label"
                            >
                                {{ $t('pages.origination.hmda.race.hawaiian.other.placeholder') }}
                            </label>
                            <div class="subRadio">
                                <form-field
                                    v-model="otherHawaiianRace"
                                    class="form-field mb-2"
                                    validation-mode="passive"
                                    validation-rules="min:2"
                                    name="otherHawaiianRace"
                                    :placeholder="$t('pages.origination.hmda.race.hawaiian.other.hint')"
                                    :label="$t('pages.origination.hmda.race.hawaiian.other.hint')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- White -->
                <div class="custom-control custom-radio">
                    <input
                        class="custom-control-input"
                        type="radio"
                        name="race"
                        value="white"
                        id="whiteRace"
                        v-model="rawRace"
                    >
                    <label
                        for="whiteRace"
                        class="custom-control-label"
                    >
                        {{ $t('pages.origination.hmda.race.white') }}
                    </label>
                </div>
                <!-- None -->
                <div class="custom-control custom-radio">
                    <input
                        class="custom-control-input"
                        type="radio"
                        name="race"
                        value="none"
                        id="noRace"
                        v-model="rawRace"
                    >
                    <label
                        for="noRace"
                        class="custom-control-label"
                    >
                        {{ $t('pages.origination.hmda.dontProvide') }}
                    </label>
                </div>
            </div>
            <form-button
                button-classes="btn btn-secondary mt-2"
                data-testid="hmda-skip-button"
                @click="skipAll"
                :label="$t('pages.origination.hmda.skipCta')"
                event-name="click_button_skip_hdma"
            />
            <form-button
                button-classes="btn btn-secondary mt-2"
                @click="handleSubmit"
                :label="$t('pages.origination.hmda.cta')"
                event-name="click_button_submit_hdma_form"
            />
        </form-container>
        <template #sidebar>
            <offer-info-card
                v-if="preQualificationOffer"
                title="Your PreQual Offer"
                :apr="preQualificationOffer.apr"
                :credit-limit="preQualificationOffer.lineSize"
            />
            <skip-list-item />
            <why-hmda-list-item />
            <hmda-app-impact-list-item />
            <help-list-item />
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormContainer from '@/components/base/FormContainer.vue'
    import FormField from '@/components/base/FormField'
    import FormButton from '@/components/base/FormButton.vue'
    import SkipListItem from '@/components/onboarding/SkipListItem'
    import WhyHmdaListItem from '@/components/onboarding/WhyHmdaListItem'
    import HmdaAppImpactListItem from '@/components/onboarding/HmdaAppImpactListItem'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { logger } from '@/utils/logger'
    import { postHmdaAnswers } from '@/services/offer'
    import { getNextRoute } from '@/flow/flowController'
    import originationMixin from '@/mixins/originationMixin'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import computePreQualificationOfferFromStorageMixin from '@/mixins/computePreQualificationOfferFromStorageMixin'

    export default {
        name: 'HomeHmda',
        components: {
            'help-list-item': HelpListItem,
            'hmda-app-impact-list-item': HmdaAppImpactListItem,
            'why-hmda-list-item': WhyHmdaListItem,
            'skip-list-item': SkipListItem,
            'onboarding-layout': OnboardingLayout,
            'form-field': FormField,
            'form-container': FormContainer,
            'form-button': FormButton,
            'offer-info-card': OfferInfoCard,
        },
        mixins: [originationMixin, computePreQualificationOfferFromStorageMixin],
        data() {
            return {
                isProcessing: true,
                isSubmitting: true,
                sex: null,
                rawEthnicity: null,
                rawHispanicEthnicity: null,
                otherHispanicEthnicity: '',
                otherAmericanIndianRace: '',
                otherAsianRace: '',
                otherHawaiianRace: '',
                rawRace: null,
                rawAsianRace: null,
                rawHawaiianRace: null,
            }
        },
        mounted: async function () {
            if (appSessionStorage.getItem(localStorageKey.alreadySubmittedHMDA) === 'true') {
                logger.info('skipping hmda page for user that has already submitted it')
                return await this.$router.push(getNextRoute(this.$router))
            }

            // Generating these documents 1 page in advance
            try {
                this.isProcessing = false
                await this.tryGoNext()
                this.$logEvent('view_hmda')
            } catch (error) {
                this.errorText = ApiErrorHandler(error)
            }
        },
        computed: {
            race: function () {
                if (this.rawRace === 'americanIndianRace') {
                    if (this.otherAmericanIndianRace === '') return 'americanIndian-other'
                    return 'americanIndian-' + this.otherAmericanIndianRace
                }

                if (this.rawRace === 'asian' && this.rawAsianRace) {
                    if (this.rawAsianRace === 'otherAsianRace') {
                        if (this.otherAsianRace === '') return 'asian-other'
                        return 'asian-' + this.otherAsianRace
                    }

                    return this.rawAsianRace
                }

                if (this.rawRace === 'hawaiian' && this.rawHawaiianRace) {
                    if (this.rawHawaiianRace === 'otherHawaiianRace') {
                        if (this.otherHawaiianRace === '') return 'hawaiian-other'
                        return 'hawaiian-' + this.otherHawaiianRace
                    }

                    return this.rawHawaiianRace
                }

                return this.rawRace
            },
            ethnicity: function () {
                if (this.rawEthnicity === 'hispanic' && this.rawHispanicEthnicity) {
                    if (this.rawHispanicEthnicity === 'otherHispanicEthnicity') {
                        if (this.otherHispanicEthnicity === '') return 'hispanic-other'
                        return 'hispanic-' + this.otherHispanicEthnicity
                    }

                    return this.rawHispanicEthnicity
                }

                return this.rawEthnicity
            },
        },
        methods: {
            tryGoNext: async function () {
                if (!this.isProcessing && !this.isSubmitting) {
                    appSessionStorage.setItem(localStorageKey.alreadySubmittedHMDA, 'true')
                    await this.$router.push(getNextRoute(this.$router))
                }
            },
            skipAll: async function () {
                logger.log('Skipping all HMDA questions...')
                this.sex = 'none'
                this.rawEthnicity = 'none'
                this.rawRace = 'none'
                return await this.handleSubmit()
            },
            handleSubmit: async function () {
                // prevent double submissions
                if (this.loading) {
                    console.log('Still loading, refusing to submit')
                    return
                }
                this.errorText = ''

                if (!this.sex || !this.ethnicity || !this.race) {
                    this.errorText = 'Please complete the form below'
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    return
                }

                this.loading = true

                logger.info(`Sex: ${this.sex}, Ethnicity: ${this.ethnicity}, Race: ${this.race}`)
                logger.info('Attempting to submit chosen HMDA questions')
                try {
                    const response = await postHmdaAnswers(this.sex, this.ethnicity, this.race)
                    logger.info(JSON.stringify(response.data))
                    this.isSubmitting = false
                    await this.tryGoNext()
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                    this.loading = false
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .custom-radio {
        margin-bottom: $spacer;
    }
    p#subTitle {
        color: $black !important;
        font-weight: $font-weight-bold !important;
    }
    .subRadio {
        display: none;
    }
    :checked ~ .subRadio {
        display: block;
        margin-top: $spacer;
    }
</style>
