<template>
    <li class="g-0 row mb-2">
        <div class="col-auto">
            <img
                class="list-item-icon"
                src="@/assets/images/global/icon-calendar.svg"
                alt="Monthly Payment"
            >
        </div>
        <div class="col text-start">
            <p class="fw-bold mb-0 mt-1">
                {{ $t('components.onboarding.monthlyPaymentListItem.title') }}
            </p>
            <div class="text-muted">
                {{ subtitle }}
            </div>
        </div>
    </li>
</template>

<script>
    import { i18n } from '@/utils/i18n'
    import format from '@/mixins/format'

    export default {
        name: 'MonthlyPaymentListItem',
        props: {
            product: { type: String, required: true },
            monthlyPayment: { type: Number, required: true },
            lineSize: { type: Number, required: true },
            apr: { type: Number, required: true },
            paymentSize: { type: Number, required: true },
            numPeriods: { type: Number, required: true },
            finalPayment: { type: Number, required: true },
            totalPayments: { type: Number, required: true },
            isExtendedLinesOffer: { type: Boolean, required: true },
        },
        mixins: [format],
        computed: {
            subtitle() {
                const dollarFormatterNoDecimal = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })
                return i18n.t(this.isExtendedLinesOffer ? 'components.onboarding.monthlyPaymentListItem.subTitleTwoFiftyK' : 'components.onboarding.monthlyPaymentListItem.subTitle', {
                    label: this.product,
                    lineSize: dollarFormatterNoDecimal.format(this.lineSize),
                    paymentSize: dollarFormatterNoDecimal.format(this.paymentSize),
                    finalPayment: dollarFormatterNoDecimal.format(this.finalPayment),
                    totalPayments: dollarFormatterNoDecimal.format(this.totalPayments),
                    numPeriodsInMonths: this.numPeriods.toString(),
                    apr: `${this.apr * 100}%`,
                    suffix: this.monthlyPayment ? ` Additional ${this.toFormattedUSDStripTrailingZeroCents(this.monthlyPayment)} monthly payment applies.` : '',
                })
            },
        },
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';
</style>
