<template>
    <div class="container pt-6">
        <div class="d-flex flex-column col-12 col-md-6 text-center m-auto">
            <div class="d-none d-md-block text-center pb-4">
                <img
                    src="@/assets/images/global/aven.svg"
                    class="logo"
                    width="71"
                    alt="Go Home"
                >
            </div>
            <div class="text-center">
                <img
                    src="../../assets/images/origination/magnifier.jpg"
                    width="200px"
                    alt="Magnifying Glass"
                >
            </div>
            <div class="text-center">
                <h5
                    class="fw-light"
                    v-html="title"
                />
            </div>
            <div class="d-grid">
                <button
                    class="btn btn-secondary mt-3"
                    @click="showWebWidget"
                    @keydown.enter="showWebWidget"
                >
                    Questions? Send Us A Message
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { i18n } from '@/utils/i18n'
    import { getIsWaitingOnManualIncomeVerification } from '@/services/api'
    import { getNextRoute } from '@/flow/flowController'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import zendeskMixin from '@/mixins/zendeskMixin'

    export default {
        mixins: [zendeskMixin],
        data() {
            return {
                title: i18n.t('awaitingManualIncomeVerification.title'),
                errorText: '',
                enableAutomaticRedirect: false,
            }
        },
        mounted() {
            if (this.enableAutomaticRedirect) {
                setTimeout(this.checkManualIncomeVerification, 15000)
            }
            this.$logEvent('view_awaiting_manual_income_verification')
        },
        methods: {
            checkManualIncomeVerification: async function () {
                try {
                    const response = await getIsWaitingOnManualIncomeVerification()
                    if (!response.data.payload.isWaiting) {
                        return await this.$router.push(getNextRoute(this.$router))
                    }
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                }
                setTimeout(this.checkManualIncomeVerification, 15000)
            },
        },
    }
</script>
