<template>
    <div>
        <div class="d-none d-md-block small text-muted text-start">
            <p class="mt-2">
                {{ $t('pages.origination.offerPreview.hardpull') }}
            </p>
        </div>
    </div>
</template>

<script>
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import { LegalDocumentTypes } from '@/services/api'
    export default {
        name: 'PreQualTerms',
        mixins: [experimentsMixin],
        props: {
            onGetDocument: { type: Function, required: true },
            isConfirmedFirstLienPosition: { type: Boolean, required: true },
        },
        data: function () {
            return {
                LegalDocumentTypes,
            }
        },
    }
</script>

<style scoped></style>
