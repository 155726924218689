<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="computedLoadingTitle"
        :title="title"
        :error-text="errorText"
        step-title="Co-Owner Identification"
    >
        <div v-if="showSpecifyIfSpouse">
            <list-button
                title="Spouse/Domestic Partner"
                @click="onSpouseVsOtherSubmit('Spouse')"
            />
            <list-button
                title="Other"
                @click="onSpouseVsOtherSubmit('Other')"
            />
        </div>

        <div v-else-if="showSorry">
            <img
                src="../../assets/images/global/workinprogress.jpg"
                width="200px"
                alt="Work In Progress"
                class="mx-auto d-block"
            >
            <p>We are currently working on automating the support for other co-owners.</p>
        </div>

        <div v-else>
            <form-container
                id="coOwnerForm"
                ref="form"
                @on-submit="submitCoOwnerInfo"
            >
                <div class="row g-2 mt-2">
                    <div class="col mt-0">
                        <form-field
                            v-model="borrowerCoOwnerFirstName"
                            validation-rules="required|min:2"
                            name="borrowerCoOwnerFirstName"
                            placeholder="First Name"
                            label="First Name"
                            key="First Name"
                        />
                    </div>
                    <div class="col mt-0">
                        <form-field
                            v-model="borrowerCoOwnerLastName"
                            validation-rules="required|min:2"
                            name="borrowerCoOwnerLastName"
                            label="Last Name"
                            placeholder="Last Name"
                            key="Last Name"
                        />
                    </div>
                    <span
                        class="small text-gray-400 mt-1"
                        v-show="borrowerCoOwnerFirstName || borrowerCoOwnerLastName"
                    >Use spouse's legal name from a valid state ID or passport</span>
                </div>
                <form-field-phone
                    class="mt-2"
                    name="phone"
                    label="Mobile Number"
                    placeholder="Mobile Number"
                    v-model="secondaryApplicantPhoneNumber"
                    validation-rules="required"
                />

                <div class="custom-control custom-checkbox mt-2">
                    <input
                        type="checkbox"
                        id="checkbox"
                        class="custom-control-input"
                        v-model="consentSecondaryCardCreation"
                    >
                    <label
                        class="custom-control-label"
                        for="checkbox"
                    >{{ $t('pages.origination.coOwner.consentSecondaryCardCreation') }}</label>
                </div>

                <form-button
                    class="mt-4"
                    type="button"
                    label="Continue"
                    :block="true"
                    :submitting="submitting"
                    @click="submitCoOwnerInfo"
                    event-name="click_button_submit_co_owner_form"
                />

                <p class="text-muted mt-1 mb-0">
                    {{ $t('pages.origination.coOwner.smsAuth') }}
                </p>
            </form-container>
        </div>
        <template #sidebar>
            <offer-info-card
                v-if="creditOffer"
                title="Your Offer"
                :apr="creditOffer.apr"
                :credit-limit="creditOffer.lineSize"
            />
            <ul class="list-unstyled list-row">
                <help-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { getCoOwnerInfo, postCoOwnerInfo } from '@/services/offer'
    import { i18n } from '@/utils/i18n'
    import { getNextRoute } from '@/flow/flowController'
    import FormFieldPhone from '@/components/base/FormFieldPhone'
    import FormField from '@/components/base/FormField'
    import ListButton from '@/components/ListButton'
    import FormContainer from '@/components/base/FormContainer'
    import FormButton from '@/components/base/FormButton'
    import originationMixin from '@/mixins/originationMixin'
    import computeCreditOfferFromStorageMixin from '@/mixins/computeCreditOfferFromStorageMixin'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import { getTrustInfo, MaritalStatus } from '@/services/api'
    import { logger } from '@/utils/logger'
    import startCase from 'lodash/startCase'
    import toLower from 'lodash/toLower'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'

    export default {
        components: {
            'onboarding-layout': OnboardingLayout,
            'form-field': FormField,
            'form-field-phone': FormFieldPhone,
            'list-button': ListButton,
            'form-container': FormContainer,
            'form-button': FormButton,
            'help-list-item': HelpListItem,
            'offer-info-card': OfferInfoCard,
        },
        mixins: [originationMixin, computeCreditOfferFromStorageMixin],
        data: function () {
            return {
                loading: true,
                numOfOwners: null,
                showMarried: false,
                showSorry: false,
                borrowerCoOwnerName: null,
                borrowerCoOwnerFirstName: '',
                borrowerCoOwnerLastName: '',
                primaryApplicantPhoneNumber: null,
                secondaryApplicantPhoneNumber: '',
                consentSecondaryCardCreation: false,
            }
        },
        mounted: async function () {
            if (this.hasCoApplicant) {
                // We want to show the co-applicant version of this page if there is a co applicant
                return await this.$router.replace(getNextRoute(this.$router))
            }

            try {
                const trustResponse = await getTrustInfo()
                if (trustResponse.data.success && trustResponse.data.payload.isTrust) {
                    logger.info('Found trust, skipping co-owner page')
                    return await this.$router.push(getNextRoute(this.$router))
                }

                const primaryMaritalStatus = appSessionStorage.getItem(localStorageKey.applicantMaritalStatus)
                this.showMarried = primaryMaritalStatus === MaritalStatus.MARRIED

                const response = await getCoOwnerInfo()
                this.numOfOwners = response.data.payload.numOfOwners
                if (this.numOfOwners > 2) {
                    console.log(`Detected more than two owners (${this.numOfOwners} to be exact), showing sorry`)
                    this.showSorry = true
                }
                this.borrowerCoOwnerName = startCase(toLower(response.data.payload.borrowerCoOwnerName))
                this.primaryApplicantPhoneNumber = response.data.payload.phoneNumber
                this.loading = false
                this.$logEvent('view_co_owner')
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }

            if (!this.showMarried && !this.borrowerCoOwnerName) {
                // Single and no co-owner, continue
                logger.info('Applicant is single and has no coOwner, continuing to next route')
                return await this.$router.push(getNextRoute(this.$router))
            }
        },
        computed: {
            showSpecifyIfSpouse: function () {
                return !!this.borrowerCoOwnerName && !this.showMarried && !this.showSorry
            },
            title: function () {
                if (this.showSpecifyIfSpouse) {
                    return `We noticed another name on the home. What is your relationship with ${this.borrowerCoOwnerName}?`
                } else if (this.showSorry) {
                    return ''
                } else {
                    this.$logEvent('view_co_owner_form')
                    return 'What is the name and mobile number of your spouse?'
                }
            },
            computedLoadingTitle: function () {
                if (this.numOfOwners) {
                    return 'Loading...'
                } else {
                    return i18n.t('pages.origination.coOwner.loading')
                }
            },
            formattedPrimaryApplicantPhoneNumber: function () {
                return (
                    this.primaryApplicantPhoneNumber.slice(0, 3) +
                    '-' +
                    this.primaryApplicantPhoneNumber.slice(3, 6) +
                    '-' +
                    this.primaryApplicantPhoneNumber.slice(6, this.primaryApplicantPhoneNumber.length)
                )
            },
        },
        watch: {
            showSorry: function (newValue) {
                if (newValue) {
                    this.$logEvent('view_co_owner_sorry')
                }
            },
        },
        methods: {
            onSpouseVsOtherSubmit: function (value) {
                if (value === 'Spouse') {
                    this.$logEvent('click_button_submit_co_owner_status_spouse')
                    // Will cause showSpecifyIfSpouse to be false
                    this.showMarried = true
                } else {
                    // other
                    this.$logEvent('click_button_submit_co_owner_status_other')
                    this.showSorry = true
                }
            },
            submitCoOwnerInfo: async function () {
                if (this.submitting) {
                    return
                }
                this.submitting = true
                this.errorText = ''

                const isValid = await this.$refs.form.$refs.observer.validate()
                if (!isValid) {
                    this.submitting = false
                    return
                }

                try {
                    if (!this.borrowerCoOwnerFirstName || !this.borrowerCoOwnerLastName) {
                        this.errorText = 'Must provide a first and last name'
                    } else if (this.secondaryApplicantPhoneNumber === this.primaryApplicantPhoneNumber) {
                        this.errorText = `Spouse's phone number can't be the same as the one we have on file for you (${this.formattedPrimaryApplicantPhoneNumber}). Please enter a different number to continue.`
                    } else {
                        const resp = await postCoOwnerInfo(this.secondaryApplicantPhoneNumber, this.borrowerCoOwnerFirstName, this.borrowerCoOwnerLastName, this.consentSecondaryCardCreation)
                        if (resp.data.success) {
                            return await this.$router.push(getNextRoute(this.$router))
                        }
                        this.errorText = `Invalid US Phone number. Please try again.`
                    }
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                }
                this.submitting = false
            },
        },
    }
</script>
