<template>
    <div
        class="modal fade"
        role="dialog"
        ref="modalWindow"
        :class="{ show: open }"
        :style="[open ? { display: 'block' } : { display: 'none' }]"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div
                        v-show="!loading && showBackButton"
                        class="navigation-icon"
                        @click="handleBackClick"
                        @keydown.enter="handleBackClick"
                    >
                        <img
                            src="../../assets/images/components/modal/backArrow.svg"
                            width="32"
                            height="32"
                            alt="Back"
                        >
                    </div>

                    <div
                        v-show="!showBackButton"
                        class="navigation-icon"
                        @click="handleCloseClick"
                        @keydown.enter="handleCloseClick"
                    >
                        <img
                            src="../../assets/images/components/modal/close.svg"
                            width="32"
                            height="32"
                            alt="Close"
                        >
                    </div>

                    <h5
                        v-show="!loading"
                        class="fw-light title"
                    >
                        {{ title }}
                    </h5>
                </div>

                <div class="modal-body">
                    <div
                        class="alert-container"
                        v-show="errorText.length > 0"
                    >
                        <div
                            class="alert alert-warning text-center"
                            role="alert"
                        >
                            <span v-html="errorText" />
                        </div>
                    </div>

                    <loading-indicator
                        class="mb-8 mt-8"
                        v-show="loading"
                        :title="loadingTitle"
                    />
                    <slot v-show="!loading" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { logger } from '@/utils/logger'
    import LoadingIndicator from '@/components/LoadingIndicator'

    export default {
        name: 'Modal',
        components: {
            LoadingIndicator,
        },
        props: {
            title: { type: String },
            showBackButton: {
                type: Boolean,
                default: false,
            },
            open: {
                type: Boolean,
                required: true,
            },
            errorText: {
                type: String,
                default: '',
            },
            loading: {
                type: Boolean,
                default: false,
            },
            loadingTitle: {
                type: String,
                required: false,
            },
        },
        data() {
            return {
                elem: null,
            }
        },
        watch: {
            open: function (value) {
                if (value) {
                    document.body.classList.add('modal-open')
                    this.elem = document.createElement('div')
                    this.elem.id = 'modal-backdrop'
                    this.elem.classList.add('modal-backdrop', 'show', 'fade')
                    document.body.appendChild(this.elem)
                    this.elem.addEventListener('click', () => {
                        this.$emit('on-modal-close-click')
                    })
                } else {
                    this.elem = null
                    document.body.classList.remove('modal-open')
                    document.getElementById('modal-backdrop').remove()
                }
            },
        },
        methods: {
            handleCloseClick() {
                logger.info('modal closure requested by user action')
                this.$emit('on-modal-close-click')
            },
            handleBackClick() {
                logger.info('modal back navigation requested by user action')
                this.$emit('on-modal-back-click')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/components/modal';
</style>
