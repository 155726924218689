import { appSessionStorage, localStorageKey } from '@/utils/storage'

const computePreQualificationOfferFromStorageMixin = {
    computed: {
        preQualificationOffer() {
            const preQualificationOffer = appSessionStorage.getItem(localStorageKey.preQualificationOffer)
            return preQualificationOffer ? JSON.parse(preQualificationOffer) : null
        },
    },
}

export default computePreQualificationOfferFromStorageMixin
