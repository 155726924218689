<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="loadingTitle"
        :error-text="errorText"
    >
        <h5
            class="fw-light text-center mb-0"
            v-html="$t('pages.origination.preQualification.title')"
        />
        <pre-qual-info-options
            class="mt-3"
            v-if="preQualOffers && preQualOffers.length > 0"
            :pre-qual-offers="preQualOffers"
            :loan-application-date="loanApplicationDate"
            :on-submit="onSubmit"
            :is-confirmed-first-lien-position="isConfirmedFirstLienPosition"
        />
        <pre-qual-terms
            class="d-none d-md-block"
            :on-get-document="getDocument"
            :is-confirmed-first-lien-position="isConfirmedFirstLienPosition"
        />
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <rate-comparison-graph-list-item
                    v-if="preQualOffers && preQualOffers.length > 0"
                    :rate="preQualOffers[0].apr"
                    :dti="dti"
                    :score="score"
                />
                <social-proof-list-item review-index="3" />
                <feedback-list-item />
            </ul>
            <no-credit-card-cost-list-item />

            <monthly-payment-list-item
                v-if="theoreticalPaymentStats"
                :product="preQualOffers[0].product"
                :monthly-payment="preQualOffers[0].monthlyFee"
                :line-size="preQualOffers[0].lineSize"
                :apr="theoreticalPaymentStats.apr"
                :payment-size="theoreticalPaymentStats.paymentSize"
                :final-payment="theoreticalPaymentStats.finalPayment"
                :num-periods="theoreticalPaymentStats.numPeriods"
                :total-payments="theoreticalPaymentStats.totalPayments"
                :is-extended-lines-offer="isExtendedLinesOffer"
            />
            <pre-qual-terms
                class="d-block d-md-none"
                :on-get-document="getDocument"
                :is-confirmed-first-lien-position="isConfirmedFirstLienPosition"
            />
        </template>
    </onboarding-layout>
</template>

<script>
    import { EXTENDED_LINES_POLICY_NAMES } from '../../utils/extendedLinePolicy'
    import OnboardingLayout from '@/layouts/Onboarding'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'
    import { i18n } from '@/utils/i18n'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import originationMixin from '@/mixins/originationMixin'
    import { logger } from '@/utils/logger'
    import { RouteOption } from '@/flow/flowUtility'
    import preQualificationMixin, { SoftPullResult, PreQualOfferAction } from '@/mixins/softPullMixin'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { getDocumentForApplicantAndOpen } from '@/utils/document'
    import { getAvenCardPreQualificationOffer, getTheoreticalPaymentStatsDuringOrigination } from '@/services/offer'
    import MonthlyPaymentListItem from '@/components/onboarding/MonthlyPaymentListItem'
    import RateComparisonGraphListItem from '@/components/onboarding/RateComparisonGraphListItem'
    import SocialProofListItem from '@/components/onboarding/SocialProofListItem'
    import NoCreditCardCostListListItem from '@/components/onboarding/NoCreditCardCostListItem'
    import PreQualInfoOptions from '@/components/PreQualInfoOptions'
    import PreQualTerms from '@/components/PreQualTerms'
    import { LegalDocumentTypes } from '@/services/api'

    export default {
        components: {
            'feedback-list-item': FeedbackListItem,
            'pre-qual-info-options': PreQualInfoOptions,
            'onboarding-layout': OnboardingLayout,
            'monthly-payment-list-item': MonthlyPaymentListItem,
            'rate-comparison-graph-list-item': RateComparisonGraphListItem,
            'social-proof-list-item': SocialProofListItem,
            'no-credit-card-cost-list-item': NoCreditCardCostListListItem,
            'pre-qual-terms': PreQualTerms,
        },
        mixins: [originationMixin, preQualificationMixin],
        data: function () {
            return {
                loading: true,
                loadingTitle: i18n.t('offerPreview.loading'),
                preQualOffers: null,
                isConfirmedFirstLienPosition: false,
                theoreticalPaymentStats: null,
            }
        },
        mounted: async function () {
            appSessionStorage.setItem(localStorageKey.currentFlow, Flows.origination)
            // isUnderwritingInfoUnchanged is checked by existence. If it exists, underwritingInfo prev existed (from DM) and was not changed so do not need to refetch data or rerun UW. If it doesn't exist,
            // either we never had UW info (non-DM) or the info was changed
            const isUnderwritingInfoUnchanged = appSessionStorage.getItem(localStorageKey.isUnderwritingInfoUnchanged)
            try {
                try {
                    if (isUnderwritingInfoUnchanged) {
                        logger.log('Underwriting info did not change, pulling last PQ offer...')
                        const preQualOfferResponse = await getAvenCardPreQualificationOffer(false)
                        if (preQualOfferResponse.data.success) {
                            logger.log(`Applicant is already pre-qualified, skipping data fetch.`)
                            const preQualOfferAction = this.getPreQualOfferActionFromResponse(preQualOfferResponse)
                            return await this.handlePreQualOfferAction(preQualOfferAction)
                        } else {
                            logger.log(`Applicant has prior pre-qual data, but was a denial`)
                        }
                    } else {
                        logger.log(`Underwriting info changed or never existed, need to fetch data and run UW`)
                    }
                } catch (e) {
                    if (e?.response?.status === 423) {
                        // HTTP 423 === "Locked"
                        logger.log(`Applicant pre-qual terms are still being generated`)
                    } else {
                        throw e
                    }
                }

                let dataFetchResult
                if (this.hasCoApplicant) {
                    dataFetchResult = await this.fetchUnderwritingDataWithCoApplicant()
                } else {
                    dataFetchResult = await this.softPullApplicant()
                }

                switch (dataFetchResult) {
                    case SoftPullResult.success: {
                        const preQualOfferResponse = await this.getPreQualOfferResponse(false /*isDmPrequal*/)
                        const preQualOfferAction = this.getPreQualOfferActionFromResponse(preQualOfferResponse)
                        return await this.handlePreQualOfferAction(preQualOfferAction)
                    }
                    case SoftPullResult.fail:
                        logger.error('Underwriting data fetch did not appear to complete, dropping into review')
                        appSessionStorage.setItem(localStorageKey.preQualificationFailureCode, 'humanInvestigate')
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'review' } })
                    case SoftPullResult.experianNotFound:
                        logger.error('Experian not found, dropping into review')
                        appSessionStorage.setItem(localStorageKey.preQualificationFailureCode, 'humanInvestigate')
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'review' } })
                    case SoftPullResult.experianFrozen:
                        return await this.$router.replace(getNextRoute(this.$router, RouteOption.experianFrozen))
                    case SoftPullResult.coApplicantExperianFrozen:
                        return await this.$router.replace(getNextRoute(this.$router, RouteOption.coApplicantExperianFrozen))
                    default:
                        logger.fatal(`Unexpected dataFetchResult in origination flow: ${dataFetchResult}`)
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'review' } })
                }
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        computed: {
            isExtendedLinesOffer: function () {
                return EXTENDED_LINES_POLICY_NAMES.includes(this.preQualOffers[0]?.policyName)
            },
        },
        methods: {
            handlePreQualOfferAction: async function (preQualOfferAction) {
                if (preQualOfferAction === PreQualOfferAction.blockRepeatApplicant) {
                    logger.info('Applicant has a prior application, showing duplicate thanks page')
                    return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'duplicate' } })
                }

                if (preQualOfferAction === PreQualOfferAction.remedyOwnerNameMismatch) {
                    logger.info('Applicant name does not match owner name, showing page to remedy name mismatch')
                    return this.$router.push(getNextRoute(this.$router, RouteOption.remedyNameMismatch))
                }

                if (preQualOfferAction === PreQualOfferAction.offerAddCoApplicant) {
                    return this.$router.push(getNextRoute(this.$router, RouteOption.addCoApplicantOnFailure))
                }

                if (preQualOfferAction === PreQualOfferAction.skipPrequal) {
                    return this.$router.push(getNextRoute(this.$router))
                }

                // if applicant saw PQ prior from DM AND isUnderwritingInfoUnchanged is truthy (it exists so we know UW info is same), then we can skip PQ offer
                if (
                    appSessionStorage.getItem(localStorageKey.inviteCode) &&
                    !appSessionStorage.getItem(localStorageKey.preQualificationFailureCode) &&
                    !!appSessionStorage.getItem(localStorageKey.isUnderwritingInfoUnchanged)
                ) {
                    logger.info('Applicant already viewed PreQualification offer. Skipping...')
                    return await this.$router.push(getNextRoute(this.$router))
                }

                // fetch pre-qual theoretical stats
                const theoreticalPaymentStatsResponse = await getTheoreticalPaymentStatsDuringOrigination(this.preQualOffers[0].apr, this.preQualOffers[0].lineSize, this.preQualOffers[0].policyName)
                this.theoreticalPaymentStats = theoreticalPaymentStatsResponse.data.payload

                // These are the dynamically generated legal docs the applicant can view on this page.
                const docsToGenerate = [LegalDocumentTypes.earlyHELOCDisclosure, LegalDocumentTypes.pricingAndTerms, LegalDocumentTypes.propertyValuation, LegalDocumentTypes.creditScoreDisclosure]
                // Purposefully not awaiting the results here because these docs are generated for
                // our record-keeping purposes and not strictly required for the UI
                // noinspection ES6MissingAwait
                this.tryGenerateAndSaveLegalDocuments(docsToGenerate)

                this.isConfirmedFirstLienPosition = !!appSessionStorage.getItem(localStorageKey.isFirstLienPosition)
                this.$logEvent('view_pre_qualification')
                this.loading = false
            },
            onSubmit: async function (preQualTerms) {
                if (this.loading) {
                    logger.log('Already submitting, ignoring button click')
                    return
                }
                this.loading = true
                try {
                    await this.choosePreQualTerms(preQualTerms)
                    return await this.$router.push(getNextRoute(this.$router))
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                }
                this.loading = false
            },
            getDocument: async function (docType) {
                try {
                    this.loadingTitle = 'Loading Document...'
                    this.loading = true
                    this.$logEvent('click_link_get_document', { docType })
                    await getDocumentForApplicantAndOpen(docType)
                } catch (e) {
                    logger.fatal(`failed to open pdfs document`, null /* event */, e)
                    this.errorText = ApiErrorHandler(e)
                }
                this.loading = false
                this.loadingTitle = i18n.t('offerPreview.loading')
            },
        },
    }
</script>
