import { httpClient } from '@/utils/http-client'

// Keep in sync with same enum in aven_backend/src/controller/updateLoanApplicationController.ts
enum CryptoOfferFailureCode {
    humanInvestigate = 'humanInvestigate',
    denied = 'denied',
    expired = 'expired',
}

const runAvenCryptoCardOffer = async () => {
    return await httpClient.post('/crypto/runAvenCardOffer')
}

const getAvenCryptoCardOffer = async (createdAfter?: string) => {
    const config = { params: { createdAfter } }
    return await httpClient.get('/crypto/getAvenCardOffer', config)
}

const acceptCryptoOffer = async () => {
    return await httpClient.post('/crypto/acceptOffer')
}

export { CryptoOfferFailureCode, runAvenCryptoCardOffer, getAvenCryptoCardOffer, acceptCryptoOffer }
