<template>
    <onboarding-layout
        :title="$t('pages.identity.title')"
        :loading="loading"
        :error-text="errorText"
        :loading-title="$t('global.loadingMessage.verifying')"
        step-title="Verify Identity"
    />
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import { verifyApplicant, verifyApplicantFullSSN } from '@/services/idology'
    import { localStorageKey, appSessionStorage } from '@/utils/storage'
    import { logger } from '@/utils/logger'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import { RouteOption } from '@/flow/flowUtility'
    import originationMixin from '@/mixins/originationMixin'

    export default {
        components: {
            OnboardingLayout,
        },
        data() {
            return {
                loading: true,
            }
        },
        mixins: [originationMixin],
        mounted: async function () {
            appSessionStorage.setItem(localStorageKey.currentFlow, Flows.origination)

            if (appSessionStorage.getItem(localStorageKey.verifiedKba) === 'true') {
                appSessionStorage.removeItem(localStorageKey.verifiedKba)
                return this.$router.replace(getNextRoute(this.$router))
            }

            try {
                await this.verify()
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        methods: {
            verify: async function () {
                const verifyFullSSNResponse = await verifyApplicantFullSSN()
                logger.info(`verifyFullSSNResponse: ${JSON.stringify(verifyFullSSNResponse.data)}`)

                switch (verifyFullSSNResponse.data.payload.status) {
                    case 'pass': {
                        // Continue to verifyApplicant()
                        logger.info('User passed full ssn check, verifying applicant.')
                        break
                    }
                    case 'fail': {
                        // user doesn't have full 9 ssn
                        logger.info('Asking Applicant for full ssn')
                        return await this.$router.replace(getNextRoute(this.$router, RouteOption.ssnVerification))
                    }
                    default:
                        this.errorText = ApiErrorHandler(new Error('postssn - Unknown response.data structure'))
                        break
                }

                const verifyApplicantResponse = await verifyApplicant()
                logger.info(`verifyApplicantResponse: ${JSON.stringify(verifyApplicantResponse.data)}`)

                if (verifyApplicantResponse.data.success) {
                    const payload = verifyApplicantResponse.data.payload
                    switch (payload.status) {
                        case 'pass': {
                            // Continue with loan application
                            logger.info('User passed verify applicant, proceeding to next route.')
                            return await this.$router.replace(getNextRoute(this.$router))
                        }
                        case 'allowableFail': {
                            logger.info('User received allowable fail, proceeding to next route.')
                            return await this.$router.replace(getNextRoute(this.$router))
                        }
                        case 'questions': {
                            // show Idology questions view
                            logger.info(`idology questions: ${JSON.stringify(payload)}`)
                            appSessionStorage.setItem(localStorageKey.identityQA, JSON.stringify(payload))
                            return await this.$router.replace(getNextRoute(this.$router, RouteOption.identityQuestions))
                        }
                        default:
                            this.errorText = ApiErrorHandler(new Error('postssn - Unknown response.data structure'))
                            break
                    }
                } else if (verifyApplicantResponse.data.error === 'INCOMPLETE_APPLICATION') {
                    logger.info(`verifyIdentity error: ${verifyApplicantResponse.data.error} -> presenting ${sharedPagePaths.THANKS}`)
                    return await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'identityFailure' } })
                } else {
                    // Want to skip this page in the event the user goes 'back'
                    await this.$router.replace(sharedPagePaths.UNABLE_TO_VERIFY_IDENTITY)
                }
            },
        },
    }
</script>

<style scoped></style>
