<template>
    <onboarding-layout :loading="true" />
</template>
<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { getApplicantReturning } from '@/services/api'
    import { logger } from '@/utils/logger'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { Flows } from '@/flow/flowController'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import assert from 'assert'
    import { sharedPagePaths } from '@/routes/sharedRoutes'

    // Todo We're moving towards deprecating OriginationReturn.vue in favor of
    //  OriginationReturnToAnything.vue and consolidating all return links. However,
    //  as of now, we still send links to applicants that route to this component:
    //  - Trust info
    //  - Income verification
    //  - Stated income
    export default {
        components: { OnboardingLayout },
        props: {
            returnPagePath: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                applicantGuid: this.$route.query?.p?.substr(0, 36),
            }
        },
        mounted: async function () {
            this.$logEvent('view_origination_return', {
                applicantGuid: this.applicantGuid,
                returnPagePath: this.returnPagePath,
            })
            try {
                if (appSessionStorage.getItem(localStorageKey.jwtTokens)) {
                    logger.info(`User hit origination return page with jwt tokens. Clearing their stale state and reloading the page to acquire a new session`)
                    appSessionStorage.clear()
                    window.location.reload()
                    return
                }

                const response = await getApplicantReturning(this.applicantGuid)
                logger.info(`Received get applicant returning response upon origination return: ${JSON.stringify(response.data)}`)

                if (response.data.error === 'PRIOR_APPLICATION_FOUND') {
                    assert(response.data.payload.returnToken2, 'no return token provided for prior loan application')
                    logger.info(`Found prior loan application, using return token ${response.data.payload.returnToken2}`)
                    return await this.$router.push({ path: sharedPagePaths.PRIORITY_APPLICATION_REDIRECTION, query: { p: response.data.payload.returnToken2 } })
                }

                appSessionStorage.setItem(localStorageKey.currentFlow, Flows.origination)

                return await this.$router.push(this.returnPagePath)
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
    }
</script>
