<template>
    <div class="container pt-6">
        <div class="d-flex flex-column col-12 col-md-6 text-center m-auto">
            <div class="d-none d-md-block text-center pb-4">
                <img
                    src="@/assets/images/global/aven.svg"
                    class="logo"
                    width="71"
                    alt="Go Home"
                >
            </div>
            <div class="text-center">
                <img
                    src="../../assets/images/origination/bankNotes.jpg"
                    width="200px"
                    alt="Bank Notes"
                >
            </div>
            <div class="text-center">
                <h5
                    class="fw-light"
                    v-html="title"
                />
            </div>
            <div class="d-grid">
                <button
                    class="btn btn-secondary mt-3"
                    @click="showWebWidget"
                    @keydown.enter="showWebWidget"
                >
                    Questions? Send Us A Message
                </button>
            </div>
            <p
                class="text-center text-muted mt-3 mb-0"
                v-html="footer"
                v-show="!!footer"
            />
        </div>
    </div>
</template>

<script>
    import { i18n } from '@/utils/i18n'
    import zendeskMixin from '@/mixins/zendeskMixin'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { appSessionStorage, localStorageKey } from '../../utils/storage'

    export default {
        mixins: [zendeskMixin],
        data() {
            const phoneNumber = appSessionStorage.getItem(localStorageKey.phoneNumber) || '?'
            const applicationNumber = appSessionStorage.getItem(localStorageKey.loanApplicationId) || '?'
            return {
                title: i18n.t('awaitingJointIncomeOfferContingencies.title'),
                footer: i18n.t('awaitingJointIncomeOfferContingencies.footer', {
                    displayEmail: contact_info.support_email,
                    displaySms: contact_info.sms,
                    mailto: `mailto:${contact_info.support_email}?subject=Blocked on Joint Income Offer Contingency (Phone #: ${phoneNumber} | Application #: ${applicationNumber})`,
                    smsTo: `sms:${contact_info.sms}`,
                }),
            }
        },
    }
</script>
