// TODO: break up this file into logical modules (setupAuth does not need to exist here && handleAuthErrors can be moved into its own mixin too)
import Vue from 'vue'
import { startCase, toLower } from 'lodash'
import { inspect, logger } from '@/utils/logger'
import { i18n } from '@/utils/i18n'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { getLead } from '@/services/auth'
import { postUpdateApplicantFields } from '@/services/api'
import { ApiErrorHandler } from '@/utils/exception-handler'
import assert from 'assert'
import { sharedPagePaths } from '@/routes/sharedRoutes'
import { getNextRoute } from '@/flow/flowController'
import { assertCtxIsType } from '@/utils/assert-helper'

export default Vue.extend({
    data: () => {
        return {
            errorField: 'none',
            errorMessage: '',
        }
    },
    methods: {
        setupAuth: async function () {
            try {
                if (appSessionStorage.getItem(localStorageKey.jwtTokens)) {
                    logger.info(`User already has jwt tokens! No need to get new ones`)
                    // the assert handles the next couple of lines
                    assertCtxIsType(this, 'loading', 'boolean')
                    // @ts-ignore
                    this.loading = false
                    return
                }

                if (!appSessionStorage.getItem(localStorageKey.phoneNumber)) {
                    logger.error(`User hit stated usage page without a phone number! Redirecting to landing page so they can supply one`)
                    appSessionStorage.clear()
                    window.location.href = '/'
                    return
                }

                const authResponse = await getLead()
                if (authResponse.data.error) {
                    await this.handleAuthErrors(authResponse)
                    return true
                }

                // the assert handles the next few lines
                assertCtxIsType(this, 'loading', 'boolean')
                // @ts-ignore
                this.primaryFirstName = startCase(toLower(authResponse?.data?.payload?.firstName))
                // @ts-ignore
                this.secondaryFirstName = startCase(toLower(authResponse?.data?.payload?.secondaryFirstName))

                // call this endpoint here only to create the home entry that we need at this point
                const response = await postUpdateApplicantFields({})
                logger.info(`basicInfo response: ${JSON.stringify(response.data)}`)

                // @ts-ignore see above assert
                this.loading = false
            } catch (e) {
                // @ts-ignore assume another assert above failed OR the api failed
                this.errorText = ApiErrorHandler(e)
            }
        },
        handleAuthErrors: async function (authResponse: any) {
            logger.info(JSON.stringify(authResponse.data))
            if (authResponse.data.error === 'INVALID_US_PHONE_NUMBER_ERROR') {
                this.errorField = 'phone'
                this.errorMessage = i18n.tc('customValidationRules.notValidPhoneNumber')
            } else if (authResponse.data.error === 'INVITE_CODE_NOT_FOUND_ERROR') {
                this.errorField = 'code'
                this.errorMessage = i18n.tc('pages.auth.error.codeNotFound')
            } else if (authResponse.data.error === 'INVITE_CODE_EXPIRED_ERROR') {
                this.errorField = 'code'
                this.errorMessage = i18n.tc('pages.auth.error.codeExpired')
            } else if (authResponse.data.error === 'WRONG_EXPERIMENT_ERROR') {
                logger.info('User is on the wrong page for their experiment. Clearing storage and reloading the page to allow user to view default landing page and try again')
                const inviteCode = appSessionStorage.getItem(localStorageKey.inviteCode)
                appSessionStorage.clear()
                const redirectPath = `/invite/${inviteCode ? '?invitecode=' + inviteCode : ''}`
                return (window.location.href = redirectPath)
            } else if (authResponse.data.error === 'EXISTING_LOAN_APPLICATION_THIS_SESSION') {
                logger.info('Found existing loan application for this session. Clearing storage and reloading the page to allow user to create a new one...')
                const startPagePath = appSessionStorage.getItem(localStorageKey.startPagePath)
                appSessionStorage.clear()
                return (window.location.href = startPagePath || '/')
            } else if (authResponse.data.error === 'PRIOR_APPLICATION_FOUND') {
                // Reset error field
                this.errorField = 'none'
                this.errorMessage = ''

                assert(authResponse.data.payload, 'no payload provided for prior loan application')
                appSessionStorage.setItem(localStorageKey.priorApplicationFoundResponseJSON, JSON.stringify(authResponse.data.payload))
                logger.info(`Found prior loan application, redirecting to ${sharedPagePaths.PROMPT_CONTINUE_PRIOR_APPLICATION}`)

                const pathAfterNextOne = getNextRoute(this.$router)
                return await this.$router.push({
                    path: sharedPagePaths.PROMPT_CONTINUE_PRIOR_APPLICATION,
                    query: {
                        a: pathAfterNextOne,
                        c: false /* isCoApplicant */,
                    } as any,
                })
            } else {
                this.errorMessage = i18n.tc('global.errors.generic')
                logger.fatal(`Unhandled ${authResponse.data.error} error in authMixin. Complete auth response: ${inspect(authResponse)}`)
            }
        },
    },
})
