<template>
    <onboarding-layout
        :title="title"
        :loading="loading"
        :error-text="errorText"
    >
        <form-container
            id="basicInfoForm"
            ref="basicInfoForm"
            @on-submit="handleBasicInfoSubmit"
            @onError="onError"
            v-if="page === Page.BasicInfo"
        >
            <section-header> TRUST INFORMATION </section-header>

            <form-field
                v-model="trustName"
                id="trustName"
                name="trustName"
                class="mt-2"
                :label="$t('pages.origination.trustInfo.basicInfo.trustName')"
                :placeholder="$t('pages.origination.trustInfo.basicInfo.trustName')"
                validation-rules="required|min:2"
            />

            <form-field-date
                v-model="dateOfExecution"
                :value="dateOfExecution"
                :key="!!initializedDateOfExecution"
                name="dateOfExecution"
                class="mt-2"
                validation-rules="required|dateRule"
                :label="$t('pages.origination.trustInfo.basicInfo.dateOfExecution')"
                :placeholder="$t('pages.origination.trustInfo.basicInfo.dateOfExecution')"
                :focus-placeholder="$t('pages.origination.trustInfo.basicInfo.dateOfExecution')"
            />

            <div class="mt-2">
                <div class="custom-control custom-radio d-inline-flex custom-control-tight">
                    <input
                        type="radio"
                        id="customRadioInline1"
                        name="customRadioInline1"
                        class="custom-control-input"
                        :value="RevocableStatus.revocable"
                        v-model="revocableStatus"
                    >
                    <label
                        class="custom-control-label custom-control-label-tight"
                        for="customRadioInline1"
                    >{{ $t('pages.origination.trustInfo.basicInfo.revocable') }}</label>
                </div>

                <div class="custom-control custom-radio d-inline-flex custom-control-tight">
                    <input
                        type="radio"
                        id="customRadioInline2"
                        name="customRadioInline2"
                        class="custom-control-input"
                        :value="RevocableStatus.irrevocable"
                        v-model="revocableStatus"
                    >
                    <label
                        class="custom-control-label custom-control-label-tight"
                        for="customRadioInline2"
                    >{{ $t('pages.origination.trustInfo.basicInfo.irrevocable') }}</label>
                </div>
            </div>

            <section-header class="mt-5">
                {{ $t('pages.origination.trustInfo.basicInfo.titleToBeTakenAs') }}
            </section-header>

            <form-text-area
                name="titleTakenAsTextField"
                ref="titleTakenAsTextField"
                class="border-primary"
                :placeholder="$t('pages.origination.trustInfo.basicInfo.titleToBeTakenAs')"
                :focus-placeholder="$t('pages.origination.trustInfo.basicInfo.beSureIsCorrect')"
                validation-rules="required|min:2"
                v-model="titleTakenAsText"
            />

            <div
                class="text-muted mt-1"
                @click="toggleTitleTakeAsExample"
                @keydown.enter="toggleTitleTakeAsExample"
            >
                <span>{{ $t('pages.origination.trustInfo.basicInfo.ifUnsure') }}</span>
                <span
                    class="title-example-toggle"
                    v-if="!showTitleExample"
                    v-html="$t('pages.origination.trustInfo.basicInfo.showTitleExample')"
                />

                <span
                    class="title-example-toggle"
                    v-if="showTitleExample"
                    v-html="$t('pages.origination.trustInfo.basicInfo.hideTitleExample')"
                />
            </div>

            <img
                v-if="showTitleExample"
                class="mt-4"
                src="@/assets/images/origination/TrustTitleExample.svg"
                alt="Title to be taken as example"
                width="100%"
            >

            <p class="small text-center fw-bold mt-4">
                {{ $t('pages.origination.trustInfo.settlorsAndTrusteesShortcuts.title') }}
            </p>
            <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    id="settlorsShortcutCheckbox"
                    class="custom-control-input"
                    v-model="settlorsShortcutSelected"
                >
                <label
                    class="custom-control-label"
                    for="settlorsShortcutCheckbox"
                    v-html="settlorsShortcutText"
                />
            </div>
            <div class="custom-control custom-checkbox mt-2">
                <input
                    type="checkbox"
                    id="trusteesShortcutCheckbox"
                    class="custom-control-input"
                    v-model="trusteesShortcutSelected"
                >
                <label
                    class="custom-control-label"
                    for="trusteesShortcutCheckbox"
                    v-html="trusteesShortcutText"
                />
            </div>

            <form-button
                class="mt-2"
                image-src="global/web_16_lock.svg"
                :label="$t('global.cta.continue')"
                type="submit"
            />

            <p class="text-muted mt-1">
                {{ $t('pages.origination.trustInfo.consent') }}
            </p>
        </form-container>

        <form-container
            id="settlorsForm"
            ref="settlorsForm"
            @on-submit="handleSettlorsSubmit"
            @onError="onError"
            v-if="page === Page.Settlors"
        >
            <!--
            Note that the Settlor object has an index property, but that's only used internally. We'll
            use the index of the Settlor in the current array of settlors for user visible labels.
            -->
            <div
                v-for="(settlor, index) in settlors"
                :key="`settlorIdx${settlor.index}`"
                class="mb-4"
            >
                <div class="d-flex">
                    <section-header class="col">
                        {{ $t('pages.origination.trustInfo.settlors.settlorNumber', { number: index + 1 }) }}
                    </section-header>
                    <button
                        class="remove-button col text-end small mb-2"
                        @click="removeSettlorWithIndex(settlor.index)"
                    >
                        Remove
                    </button>
                </div>

                <div class="row g-2">
                    <div class="col">
                        <form-field
                            v-model="settlor.firstName"
                            :id="`settlorFirstName${settlor.index}`"
                            :name="`settlorFirstName${settlor.index}`"
                            class="form-field"
                            :label="$t('components.applicationForm.placeholder.firstName')"
                            :placeholder="$t('components.applicationForm.placeholder.firstName')"
                            validation-rules="required|min:2"
                            validation-mode="aggressive"
                        />
                    </div>
                    <div class="col">
                        <form-field
                            v-model="settlor.lastName"
                            :id="`settlorLastName${settlor.index}`"
                            :name="`settlorLastName${settlor.index}`"
                            class="form-field"
                            :label="$t('components.applicationForm.placeholder.lastName')"
                            :placeholder="$t('components.applicationForm.placeholder.lastName')"
                            validation-rules="required|min:2"
                            validation-mode="aggressive"
                        />
                    </div>
                    <span
                        class="small text-gray-400 mt-1"
                        v-show="settlor.firstName || settlor.lastName"
                    >Use settlor's legal name from a valid state ID or passport</span>
                </div>

                <div class="custom-control custom-checkbox mt-2">
                    <input
                        type="checkbox"
                        :id="`settlor-can-revoke-checkbox${settlor.index}`"
                        class="custom-control-input"
                        v-model="settlor.canRevoke"
                    >
                    <label
                        class="custom-control-label"
                        :for="`settlor-can-revoke-checkbox${settlor.index}`"
                    > Can Revoke Trust </label>
                </div>
            </div>

            <button
                class="icon-button font-weight-bold mt-4"
                type="button"
                @click="addNewSettlor"
            >
                <img
                    src="@/assets/images/components/forms/buttonAddIcon.svg"
                    height="24"
                    width="24"
                    alt="Add Settlor"
                >
                <span>
                    {{ $t('pages.origination.trustInfo.settlors.addSettlor') }}
                </span>
            </button>

            <form-button
                class="mt-4"
                image-src="global/web_16_lock.svg"
                :label="$t('global.cta.continue')"
                type="submit"
            />

            <p class="text-muted mt-1">
                {{ $t('pages.origination.trustInfo.consent') }}
            </p>
        </form-container>

        <form-container
            id="trusteesForm"
            ref="trusteesForm"
            @on-submit="handleTrusteesSubmit"
            @onError="onError"
            v-if="page === Page.Trustees"
        >
            <!--
            Note that the Trustee object has an index property, but that's only used internally. We'll
            use the index of the Trustee in the current array of trustees for user visible labels.
            -->
            <div
                v-for="(trustee, index) in trustees"
                :key="`trusteeIdx${trustee.index}`"
                class="mb-4"
            >
                <div class="d-flex">
                    <section-header class="col">
                        {{ $t('pages.origination.trustInfo.trustees.trusteeNumber', { number: index + 1 }) }}
                    </section-header>
                    <button
                        class="remove-button col text-end small mb-2"
                        @click="removeTrusteeWithIndex(trustee.index)"
                    >
                        Remove
                    </button>
                </div>

                <div class="row g-2">
                    <div class="col">
                        <form-field
                            v-model="trustee.firstName"
                            :id="`trusteeFirstName${trustee.index}`"
                            :name="`trusteeFirstName${trustee.index}`"
                            :label="$t('components.applicationForm.placeholder.firstName')"
                            :placeholder="$t('components.applicationForm.placeholder.firstName')"
                            validation-rules="required|min:2"
                            validation-mode="aggressive"
                        />
                    </div>
                    <div class="col">
                        <form-field
                            v-model="trustee.lastName"
                            :id="`trusteeLastName${trustee.index}`"
                            :name="`trusteeLastName${trustee.index}`"
                            :label="$t('components.applicationForm.placeholder.lastName')"
                            :placeholder="$t('components.applicationForm.placeholder.lastName')"
                            validation-rules="required|min:2"
                            validation-mode="aggressive"
                        />
                    </div>
                    <span
                        class="small text-gray-400 mt-1"
                        v-show="trustee.firstName || trustee.lastName"
                    >Use trustee's legal name from a valid state ID or passport</span>
                </div>

                <div class="custom-control custom-checkbox mt-2">
                    <input
                        type="checkbox"
                        :id="`trustee-must-sign-checkbox${trustee.index}`"
                        class="custom-control-input"
                        v-model="trustee.mustSign"
                    >
                    <label
                        class="custom-control-label"
                        :for="`trustee-must-sign-checkbox${trustee.index}`"
                    > Must Sign To Execute Powers </label>
                </div>

                <div v-if="trustee.mustSign">
                    <form-field-phone
                        :id="`trusteePhoneNumber${trustee.index}`"
                        :name="`trusteePhoneNumber${trustee.index}`"
                        class="mt-2"
                        label="Mobile Number"
                        placeholder="Mobile Number"
                        v-model="trustee.phoneNumber"
                    />
                    <p class="text-muted mt-1">
                        {{ $t('pages.origination.trustInfo.trustees.trusteeSMS') }}
                    </p>
                </div>

                <div class="custom-control custom-checkbox mt-2">
                    <input
                        type="checkbox"
                        :id="`trustee-can-revoke-checkbox${trustee.index}`"
                        class="custom-control-input"
                        v-model="trustee.canRevoke"
                    >
                    <label
                        class="custom-control-label"
                        :for="`trustee-can-revoke-checkbox${trustee.index}`"
                    > Can Revoke Trust </label>
                </div>

                <div class="custom-control custom-checkbox mt-2">
                    <input
                        type="checkbox"
                        :id="`acquire-checkbox${trustee.index}`"
                        class="custom-control-input"
                        v-model="trustee.hasAcquirePowers"
                    >
                    <label
                        class="custom-control-label"
                        :for="`acquire-checkbox${trustee.index}`"
                    >
                        {{ $t('pages.origination.trustInfo.trustees.acquireProperty') }}
                    </label>
                </div>

                <div class="custom-control custom-checkbox mt-2">
                    <input
                        type="checkbox"
                        :id="`sell-checkbox${trustee.index}`"
                        class="custom-control-input"
                        v-model="trustee.hasSellPowers"
                    >
                    <label
                        class="custom-control-label"
                        :for="`sell-checkbox${trustee.index}`"
                    >
                        {{ $t('pages.origination.trustInfo.trustees.sellAndExecute') }}
                    </label>
                </div>

                <div class="custom-control custom-checkbox mt-2">
                    <input
                        type="checkbox"
                        :id="`encumber-checkbox${trustee.index}`"
                        class="custom-control-input"
                        v-model="trustee.hasEncumberPowers"
                    >
                    <label
                        class="custom-control-label"
                        :for="`encumber-checkbox${trustee.index}`"
                    >
                        {{ $t('pages.origination.trustInfo.trustees.encumberAndExecute') }}
                    </label>
                </div>

                <div class="custom-control custom-checkbox mt-2">
                    <input
                        type="checkbox"
                        :id="`incur-checkbox${trustee.index}`"
                        class="custom-control-input"
                        v-model="trustee.hasIncurPowers"
                    >
                    <label
                        class="custom-control-label"
                        :for="`incur-checkbox${trustee.index}`"
                    >
                        {{ $t('pages.origination.trustInfo.trustees.incurIndebtedness') }}
                    </label>
                </div>

                <div class="custom-control custom-checkbox mt-2">
                    <input
                        type="checkbox"
                        :id="`other-checkbox${trustee.index}`"
                        class="custom-control-input"
                        v-model="trustee.hasOtherPowers"
                    >
                    <label
                        class="custom-control-label"
                        :for="`other-checkbox${trustee.index}`"
                    >
                        {{ $t('pages.origination.trustInfo.trustees.other') }}
                    </label>
                </div>

                <form-field
                    v-if="trustee.hasOtherPowers"
                    v-model="trustee.otherPowersDetails"
                    :id="`trusteeHasOtherPowersDetails${trustee.index}`"
                    :name="`trusteeHasOtherPowersDetails${trustee.index}`"
                    class="mt-2"
                    :label="$t('pages.origination.trustInfo.trustees.otherDetails')"
                    :placeholder="$t('pages.origination.trustInfo.trustees.otherDetails')"
                />
            </div>

            <button
                class="icon-button font-weight-bold"
                type="button"
                @click="addNewTrustee"
            >
                <img
                    src="@/assets/images/components/forms/buttonAddIcon.svg"
                    height="24"
                    width="24"
                    alt="Add Trustee"
                >
                <span>
                    {{ $t('pages.origination.trustInfo.trustees.addTrustee') }}
                </span>
            </button>

            <form-button
                class="mt-4"
                image-src="global/web_16_lock.svg"
                :label="$t('global.cta.continue')"
                type="submit"
            />

            <p class="text-muted mt-1">
                {{ $t('pages.origination.trustInfo.consent') }}
            </p>
        </form-container>
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <information-accuracy-list-item />
                <who-needs-to-sign-list-item />
                <help-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import originationMixin from '@/mixins/originationMixin'
    import { i18n } from '@/utils/i18n'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { getNextRoute } from '@/flow/flowController'
    import FormContainer from '@/components/base/FormContainer'
    import SectionHeader from '@/components/SectionHeader'
    import FormField from '@/components/base/FormField'
    import FormFieldDate from '@/components/base/FormFieldDate'
    import FormButton from '@/components/base/FormButton'
    import InformationAccuracyListItem from '@/components/onboarding/InformationAccuracyListItem'
    import WhoNeedsToSignListItem from '@/components/onboarding/WhoNeedsToSignListItem'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import FormTextArea from '@/components/base/FormTextArea'
    import FormFieldPhone from '@/components/base/FormFieldPhone'
    import { startCase, toLower } from 'lodash'
    import assert from 'assert'
    import { getTrustInfo, postTrustInfoForm } from '@/services/api'
    import { logger } from '@/utils/logger'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { originationPagePaths } from '@/routes/originationRoutes'
    import { sharedPagePaths } from '@/routes/sharedRoutes'

    /**
     * Defines which trust info entry page the applicant should be viewing.
     * @type {{Trustees: string, Settlors: string, BasicInfo: string}}
     */
    const Page = {
        BasicInfo: 'BasicInfo',
        Settlors: 'Settlors',
        Trustees: 'Trustees',
    }

    const RevocableStatus = {
        revocable: 'revocable',
        irrevocable: 'irrevocable',
    }

    export default {
        components: {
            HelpListItem,
            InformationAccuracyListItem,
            WhoNeedsToSignListItem,
            FormButton,
            FormFieldDate,
            FormField,
            SectionHeader,
            FormContainer,
            'onboarding-layout': OnboardingLayout,
            'form-text-area': FormTextArea,
            'form-field-phone': FormFieldPhone,
        },
        mixins: [originationMixin],
        data() {
            return {
                Page,
                RevocableStatus,
                loading: true,
                page: this.$route.query?.step ?? Page.BasicInfo,
                address: '',
                dateOfExecution: '',
                initializedDateOfExecution: false,
                revocableStatus: RevocableStatus.revocable,
                settlors: [],
                trustees: [],
                trustName: '',
                titleTakenAsText: '',
                showTitleExample: false,
                settlorsShortcutSelected: false,
                trusteesShortcutSelected: false,
                /**
                 * 0 if we don't know of any settlors yet (i.e. first time through the flow). Otherwise the Settlor.index
                 * value of the last created Settlor. Useful if the applicant returns to the form and deletes all Settlors
                 * before adding another.
                 */
                lastKnownSettlorIndex: 0,
                /**
                 * 0 if we don't know of any trustees yet (i.e. first time through the flow). Otherwise the Trustee.index
                 * value of the last created Trustee. Useful if the applicant returns to the form and deletes all Trustees
                 * before adding another.
                 */
                lastKnownTrusteeIndex: 0,
            }
        },
        computed: {
            title: function () {
                if (this.page === Page.BasicInfo) {
                    return i18n.t('pages.origination.trustInfo.basicInfo.title', { address: this.address })
                } else if (this.page === Page.Settlors) {
                    return i18n.t('pages.origination.trustInfo.settlors.title')
                } else if (this.page === Page.Trustees) {
                    return i18n.t('pages.origination.trustInfo.trustees.title')
                } else {
                    logger.fatal(`Couldn't generate title for unknown page ${this.page}`)
                    return null
                }
            },
            applicantFirstNamesString: function () {
                return [this.primaryFirstName, this.secondaryFirstName]
                    .filter((name) => !!name)
                    .map((name) => startCase(toLower(name)))
                    .join(' and ')
            },
            settlorsShortcutText: function () {
                if (this.hasCoApplicant) {
                    return i18n.t('pages.origination.trustInfo.settlorsAndTrusteesShortcuts.settlorsTextPlural', { possibleSettlors: this.applicantFirstNamesString })
                }
                return i18n.t('pages.origination.trustInfo.settlorsAndTrusteesShortcuts.settlorsTextSingular', { possibleSettlors: this.applicantFirstNamesString })
            },
            trusteesShortcutText: function () {
                if (this.hasCoApplicant) {
                    return i18n.t('pages.origination.trustInfo.settlorsAndTrusteesShortcuts.trusteesTextPlural', { possibleTrustees: this.applicantFirstNamesString })
                }
                return i18n.t('pages.origination.trustInfo.settlorsAndTrusteesShortcuts.trusteesTextSingular', { possibleTrustees: this.applicantFirstNamesString })
            },
        },
        watch: {
            page: function (newValue, oldValue) {
                if (newValue === oldValue) {
                    return
                }
                if (newValue === Page.BasicInfo) {
                    this.$logEvent('view_trust_basic_info_submission')
                    this.$router.push({ path: originationPagePaths.TRUST_INFO_SUBMISSION, query: { step: Page.BasicInfo } })
                } else if (newValue === Page.Settlors) {
                    this.$logEvent('view_trust_settlor_submission')
                    this.$router.push({ path: originationPagePaths.TRUST_INFO_SUBMISSION, query: { step: Page.Settlors } })
                } else if (newValue === Page.Trustees) {
                    this.$logEvent('view_trustee_submission')
                    this.$router.push({ path: originationPagePaths.TRUST_INFO_SUBMISSION, query: { step: Page.Trustees } })
                } else {
                    logger.fatal(`Trust info submission page was something unexpected: ${newValue}`)
                }
            },
            $route: function (to) {
                // Watching the route means we can easily swap between steps if the applicant clicks back.
                this.page = to.query?.step ?? Page.BasicInfo
            },
        },
        mounted: async function () {
            try {
                const response = await getTrustInfo()
                if (!response.data.success) {
                    logger.log('This user has a trust in a RIN county, showing sorry')
                    return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'trustRIN' } })
                }

                if (!response.data.payload.isTrust) {
                    logger.log('This user does not have a trust, moving on...')
                    return await this.$router.replace(getNextRoute(this.$router))
                }

                assert(response.data.payload.address, 'No trust name returned from backend!')
                this.address = startCase(toLower(response.data.payload.address))
                this.populateFormFromPreviousSubmission(response.data.payload.previousSubmissionPayload)
                if (!this.settlors.length) {
                    this.addNewSettlor({ first: this.primaryFirstName, last: this.primaryLastName })
                    if (this.hasCoApplicant) {
                        this.addNewSettlor({ first: this.secondaryFirstName, last: this.secondaryLastName })
                    }
                }
                if (!this.trustees.length) {
                    this.addNewTrustee({ first: this.primaryFirstName, last: this.primaryLastName })
                    if (this.hasCoApplicant) {
                        this.addNewTrustee({ first: this.secondaryFirstName, last: this.secondaryLastName })
                    }
                }
                this.loading = false
                this.$logEvent('view_trust_basic_info_submission')
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        methods: {
            toggleTitleTakeAsExample: function () {
                this.showTitleExample = !this.showTitleExample
            },
            populateFormFromPreviousSubmission(payload) {
                let dateOfExecution = ''
                if (payload.dateOfExecution) {
                    const dateComponents = payload.dateOfExecution.split('-')
                    dateComponents.reverse()
                    dateOfExecution = dateComponents.join('/')
                }
                this.trustName = startCase(toLower(payload.trustName))
                this.dateOfExecution = dateOfExecution
                this.initializedDateOfExecution = true
                this.isRevocable = payload.isRevocable
                this.settlors = payload.settlors
                this.trustees = payload.trustees
                this.titleTakenAsText = payload.titleTakenAsText

                this.settlors.forEach((settlor) => (settlor.firstName = startCase(toLower(settlor.firstName))))
                this.settlors.forEach((settlor) => (settlor.lastName = startCase(toLower(settlor.lastName))))
                this.trustees.forEach((trustee) => (trustee.firstName = startCase(toLower(trustee.firstName))))
                this.trustees.forEach((trustee) => (trustee.lastName = startCase(toLower(trustee.lastName))))

                this.lastKnownSettlorIndex = this.getLastSettlorIndex()
                this.lastKnownTrusteeIndex = this.getLastTrusteeIndex()
            },
            addNewSettlor(firstAndLastName) {
                this.errorText = ''
                this.settlors.push({
                    index: this.getNextSettlorIndex(),
                    firstName: firstAndLastName?.first ?? '',
                    lastName: firstAndLastName?.last ?? '',
                    canRevoke: false,
                })
            },
            removeSettlorWithIndex: function (settlorIndex) {
                this.errorText = ''
                this.settlors = this.settlors.filter((settlor) => settlor.index !== settlorIndex)
            },
            addNewTrustee(firstAndLastName) {
                this.errorText = ''
                this.trustees.push({
                    index: this.getNextTrusteeIndex(),
                    firstName: firstAndLastName?.first ?? '',
                    lastName: firstAndLastName?.last ?? '',
                    phoneNumber: '',
                    mustSign: false,
                    canRevoke: false,
                    hasAcquirePowers: false,
                    hasSellPowers: false,
                    hasEncumberPowers: false,
                    hasIncurPowers: false,
                    hasOtherPowers: false,
                    otherPowersDetails: '',
                })
            },
            removeTrusteeWithIndex: function (trusteeIndex) {
                this.errorText = ''
                this.trustees = this.trustees.filter((trustee) => trustee.index !== trusteeIndex)
            },
            getIsSettlorDuplicated() {
                for (const settlor of this.settlors) {
                    const matchingSettlors = this.settlors.filter((existingSettlor) => {
                        return toLower(existingSettlor.firstName) === toLower(settlor.firstName) && toLower(existingSettlor.lastName) === toLower(settlor.lastName)
                    })
                    if (matchingSettlors.length !== 1) {
                        logger.info(`A settlor is duplicated, refusing to submit trust certificate info. Settlors: ${JSON.stringify(this.settlors)}`)
                        return true
                    }
                }
                return false
            },
            getIsTrusteeDuplicated() {
                for (const trustee of this.trustees) {
                    const matchingTrustees = this.trustees.filter((existingTrustee) => {
                        return toLower(existingTrustee.firstName) === toLower(trustee.firstName) && toLower(existingTrustee.lastName) === toLower(trustee.lastName)
                    })
                    if (matchingTrustees.length !== 1) {
                        logger.info(`A trustee is duplicated, refusing to submit trust certificate info. Trustees: ${JSON.stringify(this.trustees)}`)
                        return true
                    }
                }
                return false
            },
            getIsRevocableStatusConflicting() {
                const someoneCanRevoke = this.trustees.some((trustee) => trustee.canRevoke) || this.settlors.some((settlor) => settlor.canRevoke)
                const trustIsRevocable = this.revocableStatus === RevocableStatus.revocable
                return someoneCanRevoke && !trustIsRevocable
            },
            isTitleTakenAsMissingRequiredItems() {
                // Must include trust name and the date of execution
                const normalizedText = this.titleTakenAsText.toLowerCase()
                return !normalizedText.includes(this.trustName.toLowerCase().trim()) || !normalizedText.includes('date')
            },
            onError(errorText) {
                this.errorText = errorText
                this.scrollToTop()
            },
            scrollToTop() {
                document.body.scrollTop = 0 // For Safari
                document.documentElement.scrollTop = 0
            },
            scrollToErrors: function () {
                // scroll validation errors into view
                const el = document.querySelector('.is-invalid:first-of-type')
                el?.scrollIntoView()

                if (this.errorText) {
                    this.scrollToTop()
                }
            },
            handleBasicInfoSubmit: async function () {
                logger.info(`Trying to submit basic trust info with component data: ${JSON.stringify(this.$data)}`)
                this.$logEvent('click_button_submit_basic_trust_info_form', { componentData: this.$data })
                this.errorText = ''
                const isValid = await this.$refs.basicInfoForm.$refs.observer.validate()
                const isTitleTakenAsMissingRequiredItems = this.isTitleTakenAsMissingRequiredItems()

                if (isTitleTakenAsMissingRequiredItems) {
                    logger.info(`Can't submit basic trust info because title taken as is missing something: ${this.titleTakenAsText}`)
                    this.$refs.titleTakenAsTextField.applyError(`This field must contain the exact name of the trust as well as a 'dated' phrase as shown in the example below`)
                    return
                }

                if (!isValid) {
                    logger.info(`Can't submit basic trust info because form contains an invalid field`)
                    return
                }

                if (this.settlorsShortcutSelected) {
                    this.replaceAllSettlorsWithApplicantSettlors()
                    logger.info(`Applicant selected the settlors shortcut. New settlors list: ${JSON.stringify(this.settlors)}`)
                }

                if (this.trusteesShortcutSelected) {
                    this.replaceAllTrusteesWithApplicantTrustees()
                    logger.info(`Applicant selected the trustees shortcut. New trustees list: ${JSON.stringify(this.trustees)}`)
                }

                if (this.settlorsShortcutSelected && this.trusteesShortcutSelected) {
                    logger.info(`Applicant selected the shortcuts for both settlors and trustees, so submitting all trust info now!`)
                    return await this.submitAllTrustInfo()
                } else if (!this.settlorsShortcutSelected) {
                    logger.info(`Applicant did not select the settlors shortcut, so navigating to the settlors page now!`)
                    this.page = Page.Settlors
                } else {
                    logger.info(`Applicant did not select the trustees shortcut, so navigating to the trustees page now!`)
                    this.page = Page.Trustees
                }
            },
            getLastSettlorIndex: function () {
                return this.settlors.length ? Math.max(...this.settlors.map((settlor) => settlor.index)) : this.lastKnownSettlorIndex
            },
            getNextSettlorIndex: function () {
                return this.getLastSettlorIndex() + 1
            },
            replaceAllSettlorsWithApplicantSettlors: function () {
                this.settlors = [
                    {
                        index: this.getNextSettlorIndex(),
                        firstName: this.primaryFirstName,
                        lastName: this.primaryLastName,
                        canRevoke: true,
                    },
                ]
                if (this.hasCoApplicant) {
                    this.settlors.push({
                        index: this.getNextSettlorIndex(),
                        firstName: this.secondaryFirstName,
                        lastName: this.secondaryLastName,
                        canRevoke: true,
                    })
                }
            },
            getLastTrusteeIndex: function () {
                return this.trustees.length ? Math.max(...this.trustees.map((trustee) => trustee.index)) : this.lastKnownTrusteeIndex
            },
            getNextTrusteeIndex: function () {
                return this.getLastTrusteeIndex() + 1
            },
            replaceAllTrusteesWithApplicantTrustees: function () {
                this.trustees = [
                    {
                        index: this.getNextTrusteeIndex(),
                        firstName: this.primaryFirstName,
                        lastName: this.primaryLastName,
                        phoneNumber: appSessionStorage.getItem(localStorageKey.phoneNumber),
                        mustSign: true,
                        canRevoke: true,
                        hasAcquirePowers: true,
                        hasSellPowers: true,
                        hasEncumberPowers: true,
                        hasIncurPowers: true,
                        hasOtherPowers: false,
                        otherPowersDetails: '',
                    },
                ]
                if (this.hasCoApplicant) {
                    this.trustees.push({
                        index: this.getNextTrusteeIndex(),
                        firstName: this.secondaryFirstName,
                        lastName: this.secondaryLastName,
                        phoneNumber: appSessionStorage.getItem(localStorageKey.coApplicantPhoneNumber),
                        mustSign: true,
                        canRevoke: true,
                        hasAcquirePowers: true,
                        hasSellPowers: true,
                        hasEncumberPowers: true,
                        hasIncurPowers: true,
                        hasOtherPowers: false,
                        otherPowersDetails: '',
                    })
                }
            },
            handleSettlorsSubmit: async function () {
                logger.info(`Trying to submit settlors with component data: ${JSON.stringify(this.$data)}`)
                this.$logEvent('click_button_submit_trust_settlor_info_form', { componentData: this.$data })
                this.errorText = ''

                const isValid = await this.$refs.settlorsForm.$refs.observer.validate()
                if (!isValid) {
                    logger.info(`Specified settlors are invalid`)
                    return
                }

                const atLeastOneSettlor = this.settlors.length >= 1
                const isSettlorDuplicated = this.getIsSettlorDuplicated()
                if (!atLeastOneSettlor) {
                    this.errorText = "Please make sure you include all the trust's settlors or grantors"
                } else if (isSettlorDuplicated) {
                    this.errorText = 'It looks like a settlor is duplicated. Please submit only one entry for each settlor'
                }
                const badConditions = {
                    noSettlors: !atLeastOneSettlor,
                    isSettlorDuplicated,
                }
                if (Object.values(badConditions).some((condition) => !!condition)) {
                    console.log(`Found error conditions when submitting settlors ${JSON.stringify(badConditions)}`)
                    this.scrollToErrors()
                    return
                }

                if (this.trusteesShortcutSelected) {
                    logger.info(`Applicant did not selected the trustees shortcut, so submitting all trust info now!`)
                    await this.submitAllTrustInfo()
                    return
                } else {
                    logger.info(`Applicant did not select the trustees shortcut, so navigating to the trustees page now!`)
                    this.page = Page.Trustees
                }
            },
            handleTrusteesSubmit: async function () {
                logger.info(`Trying to submit trustees with component data: ${JSON.stringify(this.$data)}`)
                this.$logEvent('click_button_submit_trustee_form', { componentData: this.$data })
                this.errorText = ''

                const isValid = await this.$refs.trusteesForm.$refs.observer.validate()
                if (!isValid) {
                    logger.info(`Specified trustees are invalid`)
                    return
                }

                const isMustSignTrusteeCountExcessive = this.trustees.filter((trustee) => !!trustee.mustSign).length > 6
                const noTrusteeIsSigningWithPowers = this.trustees.filter((trustee) => !!trustee.mustSign && !!trustee.hasIncurPowers && !!trustee.hasEncumberPowers).length === 0
                const isTrusteeDuplicated = this.getIsTrusteeDuplicated()
                if (isTrusteeDuplicated) {
                    this.errorText = 'It looks like a trustee is duplicated. Please submit only one entry for each trustee'
                } else if (isMustSignTrusteeCountExcessive) {
                    this.errorText =
                        'Our system can accommodate at most 6 trustees that must sign. If the information supplied is accurate, please reach out to our customer support for another arrangement'
                } else if (noTrusteeIsSigningWithPowers) {
                    this.errorText = 'At minimum one trustee will need to sign with the powers to encumber and execute deeds of trust, as well as incur indebtedness'
                }
                const badConditions = {
                    isMustSignTrusteeCountExcessive,
                    noTrusteeIsSigningWithPowers,
                    isTrusteeDuplicated,
                }
                if (Object.values(badConditions).some((condition) => !!condition)) {
                    console.log(`Found error conditions when submitting trustees ${JSON.stringify(badConditions)}`)
                    this.scrollToErrors()
                    return
                }

                logger.info(`Trustees submitted; now submitting all trust info!`)
                await this.submitAllTrustInfo()
            },
            submitAllTrustInfo: async function () {
                logger.info(`Trying to submit all trust info`)
                this.submitting = true
                try {
                    const isRevocableStatusConflicting = this.getIsRevocableStatusConflicting()
                    if (isRevocableStatusConflicting) {
                        this.errorText = 'It looks like the trust is marked irrevocable, yet a settlor or trustee can revoke it. Please resolve this conflict before continuing'
                        this.scrollToTop()
                        return
                    }

                    const postBody = {
                        trustName: this.trustName,
                        dateOfExecution: this.dateOfExecution,
                        isRevocable: this.revocableStatus === RevocableStatus.revocable,
                        settlors: this.settlors,
                        trustees: this.trustees,
                        titleTakenAsText: this.titleTakenAsText,
                    }
                    const response = await postTrustInfoForm(postBody)
                    if (!response.data.success) {
                        logger.info(`Got unsuccessful trust form submission response with error ${response.data.error}`)
                        this.errorText = 'Invalid phone number detected. Please review your information and try again'
                        this.scrollToTop()
                    } else {
                        await this.$logEvent('event_trust_info_submitted_successfully', { data: postBody })
                        return await this.$router.push(getNextRoute(this.$router))
                    }
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                    this.scrollToTop()
                } finally {
                    this.submitting = false
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .title-example-toggle {
        cursor: pointer;
    }
    .remove-button {
        background: none;
        outline: none;
        border: none;
        text-decoration: underline;
        cursor: pointer;
        color: $gray-400;
        font-weight: $font-weight-normal;
    }
</style>
