<template>
    <onboarding-layout
        :title="title"
        :error-text="errorText"
        step-title="Step 3 of 3"
    >
        <form-container
            id="employerEmailForm"
            ref="form"
            @on-submit="onSubmit"
        >
            <form-field
                class="mb-3"
                v-model="employerEmail"
                validation-mode="passive"
                validation-rules="'required|min:5'"
                name="employerEmail"
                ref="employerEmail"
                :placeholder="$t('pages.origination.employerEmail.emailPlaceholder')"
            />

            <form-button
                :label="$t('pages.origination.employerEmail.cta')"
                class="mb-3"
                :submitting="submitting"
                type="submit"
                @click="onSubmit"
                event-name="click_button_submit_employer_email_form"
            />

            <div class="text-center">
                <div
                    class="text-dark fw-bold"
                    style="display: inline-block; cursor: pointer"
                    @click="onClickHelp"
                    @keydown.enter="onClickHelp"
                    v-html="$t('pages.origination.employerEmailCodeVerification.help')"
                />
            </div>
        </form-container>
        <template #sidebar>
            <verify-employment-list-item />
            <help-list-item />
        </template>

        <email-verification-help-modal
            ref="emailVerificationHelpModal"
            :show-try-again="false"
            @on-click-go-back="onSelectGoBack"
        />
    </onboarding-layout>
</template>

<script lang="js">
    import OnboardingLayout from "@/layouts/Onboarding";
    import VerifyEmploymentListItem from "@/components/onboarding/VerifyEmploymentListItem";
    import HelpListItem from "@/components/onboarding/HelpListItem";
    import FormField from "@/components/base/FormField"
    import FormContainer from "@/components/base/FormContainer";
    import FormButton from "@/components/base/FormButton";
    import EmailVerificationHelpModal from "@/components/modal/EmailVerificationHelpModal";
    import {updateEmployerEmail} from "@/services/loanApplication";
    import {logger} from "@/utils/logger";
    import {appSessionStorage, localStorageKey} from "@/utils/storage";
    import {misc} from "@/mixins/misc";
    import {ApiErrorHandler} from "@/utils/exception-handler";
    import {getNextRoute} from "@/flow/flowController";
    import {i18n} from "@/utils/i18n";
    import originationMixin from "@/mixins/originationMixin";
    import incomeVerificationMixin from "@/mixins/incomeVerificationMixin";

    export default {
        components: {
            'verify-employment-list-item': VerifyEmploymentListItem,
            'help-list-item': HelpListItem,
            "form-container": FormContainer,
            "form-field": FormField,
            "form-button": FormButton,
            "onboarding-layout": OnboardingLayout,
            EmailVerificationHelpModal,
        },
        data() {
            return {
                employerEmail: "",
                submittingNoEmail: false,
            };
        },
        mixins: [misc, originationMixin, incomeVerificationMixin],
        watch: {
            employerEmail(value) {
                this.throttleLogging(value, 'aven employerEmail')
            },
        },
        mounted() {
            this.$logEvent('view_employer_email')
        },
        computed: {
            title() {
                if (!appSessionStorage.getItem(localStorageKey.coApplicantJwtTokens)) {
                    // Individual applicant
                    return i18n.t('pages.origination.employerEmail.title')
                } else {
                    // Primary or co-applicant
                    return i18n.t('pages.origination.employerEmail.titleWithCoApplicant', {firstName: this.verifyingApplicantName})
                }
            },
        },
        methods: {
            onSubmit: async function() {
                if (this.submitting || this.submittingNoEmail) { return; }
                this.submitting = true

                // Check if form is valid
                const isValid = await this.$refs.form.$refs.observer.validate()
                if (!isValid) { this.submitting = false; return; }

                const employerEmail = this.employerEmail.trim()
                logger.info(`Company Email: ${employerEmail}`)
                try {

                    await updateEmployerEmail(employerEmail, this.isCoApplicantVerifying)
                    appSessionStorage.setItem(localStorageKey.employerEmail, employerEmail)
                    return await this.$router.push(getNextRoute(this.$router))

                } catch (error) {
                    if (error.response?.status === 400) {
                        logger.info(`invalid email input - ${error}`)
                        this.$refs.employerEmail.applyError(i18n.t('pages.origination.employerEmail.invalidEmail'))
                        this.$logEvent('error_message', { message: this.errorText })
                    } else {
                        this.errorText = ApiErrorHandler(error)
                    }
                }
                this.submitting = false
            },
            onClickHelp: async function() {
                this.$refs.emailVerificationHelpModal.expand()
            },
            onSelectGoBack: async function() {
                await this.$router.back()
            },
        },
    };
</script>
