<template>
    <div
        class="upload-button"
        :class="{ 'is-uploaded': isComplete }"
    >
        <p class="fw-bold mb-0">
            {{ title }}
        </p>
        <div
            v-if="isSubmitting"
            class="spinner-container"
        >
            <div class="spinner-border spinner-border-sm" />
        </div>
        <p
            v-else-if="!isComplete"
            class="incomplete-text mb-0"
        >
            {{ incompleteText }}
        </p>
        <p
            class="complete-text mb-0"
            v-else
        >
            {{ completeText }}
        </p>
        <!-- TODO: should be accessible -->
        <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
        <input
            :disabled="disabled"
            type="file"
            style="position: absolute; opacity: 0"
            @click="handleOnClick"
            @change="handleFileChange($event.target.files)"
            :accept="accept"
        >
    </div>
</template>

<script>
    import { logger } from '@/utils/logger'

    export default {
        name: 'UploadButton',
        props: {
            title: {
                type: String,
                required: true,
            },
            incompleteText: {
                type: String,
                required: true,
            },
            completeText: {
                type: String,
                required: true,
            },
            isComplete: {
                type: Boolean,
                required: true,
            },
            isSubmitting: {
                type: Boolean,
                required: false,
                default: false,
            },
            accept: {
                type: String,
                required: false,
                default: 'image/*',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                clickCount: 0,
            }
        },
        methods: {
            handleFileChange(file) {
                // if the file has changed, we can reset the clickCount because we'll assume they're just reuploading a different file
                this.clickCount = 0

                this.$emit('on-file-change', file)
            },
            handleOnClick() {
                this.clickCount += 1
                logger.info(`User clicked button ${this.clickCount} times`)
                if (this.clickCount > 5) {
                    // user clicked this upload button more than a generous 5 times, triggering a sentry so someone can investigate
                    logger.fatal('User clicked upload button too many times without uploading a file, requires investigation')
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/uploadButton';
</style>
