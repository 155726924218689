<template>
    <div
        v-if="!href"
        @click="handleClick"
        @keydown.enter="handleClick"
        class="list-button"
        :class="{ 'has-image': imageSrc }"
    >
        <img
            v-if="imageSrc"
            :src="getImageSrc"
            :alt="title"
        >
        <span>{{ title }}</span>
    </div>

    <a
        v-else
        :href="href"
        class="list-button"
        :class="{ 'has-image': imageSrc }"
    >
        <img
            v-if="imageSrc"
            :src="getImageSrc"
            :alt="title"
        >
        <span>{{ title }}</span>
    </a>
</template>

<script>
    export default {
        name: 'ListButton',
        props: {
            title: {
                type: String,
                required: true,
            },
            href: {
                type: String,
                required: false,
            },
            imageSrc: {
                type: String,
                required: false,
            },
        },
        methods: {
            handleClick: function (event) {
                this.$emit('click', event)
            },
        },
        computed: {
            getImageSrc: function () {
                return require(`../assets/images/${this.imageSrc}`)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/listButton';
</style>
