<template>
    <li class="g-0 row mb-2">
        <div class="col text-start">
            <img
                class="list-item-icon"
                src="@/assets/images/global/trustpilot_logo.svg"
                alt="TrustPilot Logo"
            >
            <tiny-slider class="mt-2">
                <social-proof-item
                    :reviewer-name="review.name"
                    :review-title="review.title"
                    :created-date="review.createdDate"
                    :updated-date="review.updatedDate"
                    :review="review.content"
                />
            </tiny-slider>
        </div>
    </li>
</template>

<script>
    import 'tiny-slider/src/tiny-slider.scss'
    import VueTinySlider from 'vue-tiny-slider'
    import SocialProofItem from '@/components/onboarding/SocialProofItem'
    import { reviews } from '@/utils/reviews'

    export default {
        name: 'SocialProofListItem',
        props: {
            reviewIndex: {
                type: Number,
            },
        },
        data() {
            return {
                reviews,
            }
        },
        computed: {
            review() {
                // use william's review by default
                return this.reviews[this.reviewIndex ?? 2]
            },
        },
        components: {
            'tiny-slider': VueTinySlider,
            'social-proof-item': SocialProofItem,
        },
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';
</style>
