import Vue from 'vue'
import throttle from 'lodash/throttle'
import { logger } from '@/utils/logger'
import { getLegalDocumentDownload } from '@/services/api'
import { isIOSSafari } from '@/utils/parseUserAgents'

export const misc = Vue.extend({
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        throttleLogging: throttle((inputValue, logPrefix) => {
            if (inputValue) {
                logger.info(`${logPrefix}: ${inputValue}`)
            }
        }, 1000),
        // loads a pdf document from the backend with the specified docType
        // pushes blob to ViewPdfDocument.vue for presentation
        loadPdfDocument: async function (docType: string, isPrimaryApplicant = true) {
            try {
                this.loading = true
                let response: any
                if (isPrimaryApplicant) {
                    response = await getLegalDocumentDownload(docType)
                } else {
                    // response = await getCoApplicantDocument(docType)
                }
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], { type: 'application/pdf' })
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file)
                if (isIOSSafari()) {
                    window.location.href = fileURL
                } else {
                    window.open(fileURL, '_blank')
                }
            } catch (error) {
                logger.fatal('Download document failed', null /* event */, error)
            }
            this.loading = false
        },
        scrollToTop() {
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0
        },
    },
})
