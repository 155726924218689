<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="$t('pages.origination.welcome.loading')"
        :error-text="errorText"
    >
        <div class="text-center">
            <img
                src="../../assets/images/origination/ThankYou@2x.jpg"
                class="mb-3 img-fluid"
                alt="Celebration"
            >
        </div>

        <h5
            class="text-center fw-bold"
            v-html="$t('pages.origination.welcome.title')"
        />

        <div class="mt-2">
            <span v-html="$t('pages.origination.welcome.timeLine')" />
        </div>

        <div class="d-grid">
            <a
                :href="baseUrl + '/app'"
                class="btn btn-primary mt-4"
            > Download The App</a>
        </div>
        <p class="text-muted small mt-4">
            <i18n path="pages.origination.welcome.nextSteps">
                <template #linkText>
                    <a
                        v-html="$t('pages.origination.welcome.nextStepsLinkText')"
                        href="#"
                        @click="getDocument('AccountAgreement')"
                    />
                </template>
            </i18n>
        </p>
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <help-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import originationMixin from '@/mixins/originationMixin'
    import format from '@/mixins/format'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import { getDocuSignLegalDownload } from '@/services/api'
    import { isIOSSafari } from '@/utils/parseUserAgents'
    import { inspect, logger } from '@/utils/logger'
    import { i18n } from '@/utils/i18n'

    export default {
        components: {
            HelpListItem,
            OnboardingLayout,
        },
        mixins: [originationMixin, format],
        data() {
            return {
                loading: false,
                baseUrl: process.env.VUE_APP_AVEN_URL,
            }
        },
        mounted: function () {
            this.$logEvent('view_crypto_welcome')
        },
        methods: {
            getDocument: async function (docType) {
                this.loading = true
                try {
                    const response = await getDocuSignLegalDownload(docType)
                    const file = new Blob([response.data], { type: 'application/pdf' })
                    const fileURL = URL.createObjectURL(file)
                    if (isIOSSafari()) {
                        window.location.href = fileURL
                    } else {
                        window.open(fileURL, '_blank')
                    }
                } catch (e) {
                    logger.fatal(`failed to open pdfs document due to error: ${inspect(e)}`)
                    this.errorText = i18n.tc('global.errors.generic')
                }
                this.loading = false
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/components/welcome';
</style>
