import Persona, { Client } from 'persona'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { Fields, InquiryError } from 'persona/dist/lib/interfaces'

// https://docs.withpersona.com/docs/models-lifecycle
export enum PersonaStatus {
    created = 'created',
    pending = 'pending',
    completed = 'completed',
    failed = 'failed',
    expired = 'expired',
    needsReview = 'needs-review',
    approved = 'approved',
    declined = 'declined',
}

type OnPersonaReady = () => {}
type OnPersonaComplete = ({ inquiryId, status, fields }: { inquiryId: string; status: string; fields: Fields }) => {}
type OnPersonaCancel = ({ inquiryId, sessionToken }: { inquiryId?: string | undefined; sessionToken?: string | undefined }) => {}
type OnPersonaError = (error: InquiryError) => {}

export class PersonaClient {
    public personaClient: Client

    constructor(onReady: OnPersonaReady, onComplete: OnPersonaComplete, onCancel: OnPersonaCancel, onError: OnPersonaError) {
        this.personaClient = new Persona.Client({
            templateId: process.env.VUE_APP_PERSONA_TEMPLATE_ID,
            environment: process.env.VUE_APP_PERSONA_ENV,
            onReady,
            onComplete,
            onCancel,
            onError,
            fields: {
                nameFirst: appSessionStorage.getItem(localStorageKey.firstName),
                nameLast: appSessionStorage.getItem(localStorageKey.lastName),
                // birthdate: appSessionStorage.getItem(localStorageKey.dateOfBirth), // TODO Crypto: Add back after addressed with Persona
                addressStreet1: JSON.parse(appSessionStorage.getItem(localStorageKey.basicInfo))?.addressData?.addressComponents?.addressStreet,
                addressCity: JSON.parse(appSessionStorage.getItem(localStorageKey.basicInfo))?.addressData?.addressComponents?.addressCity,
                addressSubdivision: JSON.parse(appSessionStorage.getItem(localStorageKey.basicInfo))?.addressData?.addressComponents?.addressState,
                addressPostalCode: JSON.parse(appSessionStorage.getItem(localStorageKey.basicInfo))?.addressData?.addressComponents?.addressPostalCode,
                addressCountryCode: JSON.parse(appSessionStorage.getItem(localStorageKey.basicInfo))?.addressData?.addressComponents?.country,
                phoneNumber: appSessionStorage.getItem(localStorageKey.phoneNumber),
                emailAddress: appSessionStorage.getItem(localStorageKey.applicantEmail),
            },
        })
    }
}
