import Vue from 'vue'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { startCase, toLower } from 'lodash'

export default Vue.extend({
    data: function () {
        return {
            currentlyUploadingIndex: null,
            uploadFileList: [],
        }
    },
    computed: {
        isCoApplicantVerifying() {
            if (appSessionStorage.getItem(localStorageKey.coApplicantJwtTokens)) {
                const statedIncome = parseInt(appSessionStorage.getItem(localStorageKey.statedIncome) as string)
                const coApplicantStatedIncome = parseInt(appSessionStorage.getItem(localStorageKey.coApplicantStatedIncome) as string)
                return statedIncome < coApplicantStatedIncome
            }
            return false
        },
        verifyingApplicantName() {
            if (!this.isCoApplicantVerifying) {
                return startCase(toLower(appSessionStorage.getItem(localStorageKey.firstName) || undefined)) || ''
            } else {
                return startCase(toLower(appSessionStorage.getItem(localStorageKey.coApplicantFirstName) || undefined)) || ''
            }
        },
    },
    methods: {
        isDocumentUploading(index: number) {
            return this.currentlyUploadingIndex === index
        },
        hasDocumentUploaded(index: number) {
            return !!this.uploadFileList[index - 1]
        },
        getFileInfo: function (file: any) {
            return JSON.stringify({
                name: file.name,
                size: file.size,
                lastModifiedDate: file.lastModifiedDate,
            })
        },
    },
})
