<template>
    <div>
        <modal
            v-if="preQualOffers && preQualOffers.length > 0"
            :open="modalOpen"
            @on-modal-close-click="closeModal"
        >
            <p class="text-center fw-bold fs-5">
                {{ $t('pages.origination.preQualification.qualifyForUpToModal') }}
            </p>

            <h1 class="text-center">
                {{ toFormattedUSDStripTrailingZeroCents(maxOfferLineSize) }}
            </h1>

            <p class="text-center">
                {{ $t('pages.origination.preQualification.selectPreferredOffer') }}
            </p>

            <button
                v-for="(offer, index) in preQualOffers"
                :key="index"
                :data-testid="`prequal-info-select-offer-${index}`"
                class="d-block w-100 bg-white border rounded-2 px-3 py-1 mb-2 position-relative"
                :class="selectedOfferIndex == index ? 'border-dark border-2' : 'border-secondary'"
                @click="selectOffer(index)"
            >
                <div class="fw-bold">
                    {{ toFormattedUSDStripTrailingZeroCents(offer.lineSize) }}
                </div>
                {{ $t('pages.origination.preQualification.modalApr', { apr: formatApr(offer.apr) }) }}

                <div
                    v-if="selectedOfferIndex == index"
                    class="bg-dark position-absolute end-0 bottom-0 rounded-2-diagonal"
                >
                    <img
                        src="@/assets/images/origination/check-white.svg"
                        alt="Check Icon"
                    >
                </div>
            </button>

            <button
                class="mb-2 btn btn-primary w-100"
                data-testid="prequal-info-change-offer-continue"
                @click="onModalContinue"
                @keydown.enter="onModalContinue"
            >
                {{ $t('pages.origination.preQualification.modalContinue') }}
            </button>
        </modal>
        <div
            v-if="preQualOffers && preQualOffers.length > 0"
            class="card card-shadow rounded-3 mt-2 p-3 px-md-5"
        >
            <div class="text-center">
                <h2
                    class="mt-4 mb-0"
                    data-testid="pre-qual-info-options-line-size"
                >
                    {{ toFormattedUSDStripTrailingZeroCents(selectedPreQualOffer.lineSize) }}
                </h2>

                <p class="fw-bold mb-0">
                    {{ $t('pages.origination.preQualification.creditLimit') }}
                </p>

                <p
                    v-if="preQualOffers.length > 1"
                    class="small mb-0"
                >
                    <span v-if="selectedOfferIndex == 0">{{ $t('pages.origination.preQualification.qualifyForUpTo', { maxOffer: toFormattedUSDKs(maxOfferLineSize) }) }}</span>
                    &nbsp;
                    <a
                        class="text-underline cursor-pointer"
                        data-testid="prequal-info-change-offer-open-modal-button"
                        @click="openModal"
                        @keydown.enter="openModal"
                    >{{
                        $t('pages.origination.preQualification.changeQuestion')
                    }}</a>?
                </p>

                <img
                    class="mt-4"
                    src="@/assets/images/origination/BlankCardVisa.jpg"
                    width="100%"
                    alt="Sample VISA Card"
                >
            </div>

            <div class="d-flex flex-column mt-4">
                <div class="d-inline-flex justify-content-between align-items-center w-100">
                    <div>
                        <p
                            class="mb-0"
                            v-html="$t('pages.origination.preQualification.apr')"
                        />
                        <div
                            class="small text-muted"
                            v-html="$t('pages.origination.offerPreview.autopayDiscount')"
                        />
                    </div>
                    <h5
                        class="mb-0"
                        v-html="formatApr(selectedPreQualOffer.apr)"
                    />
                </div>

                <divider-line class="p-0" />

                <div class="d-inline-flex justify-content-between align-items-center w-100">
                    <p
                        class="mb-0"
                        v-html="$t('pages.origination.preQualification.cashBack')"
                    />
                    <h5
                        class="mb-0"
                        v-html="toFormattedAprStripTrailingZerosAfterDecimalPoint(selectedPreQualOffer.cashBack)"
                    />
                </div>

                <divider-line class="p-0" />

                <div class="d-inline-flex justify-content-between align-items-center w-100">
                    <p class="mb-0">
                        {{ $t('pages.origination.preQualification.annualFee') }}
                    </p>
                    <h5 class="mb-0">
                        $0
                    </h5>
                </div>

                <divider-line class="p-0" />

                <div class="d-inline-flex justify-content-between align-items-center w-100">
                    <p class="mb-0">
                        {{ $t('pages.origination.preQualification.signUpFee') }}
                    </p>
                    <h5 class="mb-0">
                        $0
                    </h5>
                </div>

                <divider-line class="p-0" />

                <div class="d-inline-flex justify-content-between align-items-center w-100">
                    <p class="mb-0">
                        {{ $t('pages.origination.preQualification.recordingFee') }}
                    </p>
                    <h5 class="mb-0">
                        $0
                    </h5>
                </div>

                <div class="rounded-2 bg-light d-flex p-2 mt-4">
                    <img
                        class="me-2"
                        src="@/assets/images/origination/clock-green.svg"
                        alt="Clock Icon"
                    >
                    <div>
                        <div class="fw-bold text-green">
                            {{ $t('pages.origination.preQualification.timeRemaining', { time }) }}
                        </div>
                        <div class="small">
                            {{ $t('pages.origination.preQualification.secureYourOffer') }}
                        </div>
                    </div>
                </div>
            </div>

            <form-button
                class="mt-2"
                data-testid="prequal-info-options-continue-button"
                @click="onSubmit(selectedPreQualOffer)"
                :submitting="submitting"
                event-name="click_button_pre_qualification_continue"
                :event-props="selectedPreQualOffer"
            >
                {{ $t('pages.origination.preQualification.getYourCard') }}
            </form-button>

            <safe-credit-score class="mt-2 mb-4" />
        </div>
    </div>
</template>

<script>
    import { DateTime } from 'luxon'
    import format from '@/mixins/format'
    import FormButton from '@/components/base/FormButton'
    import Modal from '@/components/modal/Modal'
    import DividerLine from '@/components/DividerLine'
    import SafeCreditScore from '@/components/SafeCreditScore'

    export default {
        name: 'PreQualInfoOptions',
        components: {
            'form-button': FormButton,
            modal: Modal,
            'divider-line': DividerLine,
            'safe-credit-score': SafeCreditScore,
        },
        mixins: [format],
        props: {
            preQualOffers: { type: Array, default: [] },
            loanApplicationDate: { type: Date, require: false },
            onSubmit: { type: Function, require: true },
            submitting: { type: Boolean, default: false },
            isConfirmedFirstLienPosition: { type: Boolean, required: true },
        },
        data: function () {
            return {
                time: null,
                intervalId: null,
                modalOpen: false,
                selectedOfferIndex: 0,
            }
        },
        methods: {
            openModal: function () {
                this.modalOpen = true
            },
            closeModal: function () {
                this.modalOpen = false
            },
            onModalContinue: function () {
                this.modalOpen = false
            },
            selectOffer: function (index) {
                this.selectedOfferIndex = index
            },
        },
        computed: {
            maxOfferLineSize: function () {
                if (this.preQualOffers && this.preQualOffers.length > 0) {
                    return Math.max(...this.preQualOffers.map((o) => o.lineSize))
                }

                return 0
            },
            selectedPreQualOffer: function () {
                return this.preQualOffers[this.selectedOfferIndex]
            },
        },
        mounted: function () {
            this.intervalId = setInterval(() => {
                const applicationStartDate = DateTime.fromJSDate(this.loanApplicationDate)
                const minutesSinceApplicationStartDate = DateTime.now().diff(applicationStartDate, ['minutes']).toObject().minutes
                const minutesUntilNextDeadline = 30 - (minutesSinceApplicationStartDate % 30)

                this.time = DateTime.now().plus({ minutes: minutesUntilNextDeadline }).diff(DateTime.now()).toFormat('mm:ss')
            }, 1000)
        },
        beforeDestroy: function () {
            clearInterval(this.intervalId)
        },
    }
</script>

<style lang="scss" scoped>
    .cursor-pointer {
        cursor: pointer;
    }

    .custom-prime-pill {
        color: $black;
        background-color: #add6e3;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .rounded-2-diagonal {
        border-top-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    .text-green {
        color: #499f6e;
    }

    .bg-gray {
        background-color: #f2f4f5;
    }
</style>
