<template>
    <div class="bg-white p-3 rounded-3">
        <div class="small-text fw-bold">
            {{ reviewerName }}
        </div>
        <div class="d-flex flex-row mt-1">
            <img
                class="star"
                src="@/assets/images/global/trustpilot-rating-star.svg"
                alt="Rating first star"
            ><img
                class="star"
                src="@/assets/images/global/trustpilot-rating-star.svg"
                alt="Rating second star"
            ><img
                class="star"
                src="@/assets/images/global/trustpilot-rating-star.svg"
                alt="Rating third star"
            ><img
                class="star"
                src="@/assets/images/global/trustpilot-rating-star.svg"
                alt="Rating fourth star"
            ><img
                class="star"
                src="@/assets/images/global/trustpilot-rating-star.svg"
                alt="Rating fifth star"
            >
        </div>
        <div class="small-text mt-2 text-muted">
            <img
                class="star"
                src="@/assets/images/global/check-circled.svg"
                alt="Circled Check"
            >
            {{ date }}
        </div>
        <div class="mt-2 fw-bolder">
            {{ reviewTitle }}
        </div>
        <div class="mt-2">
            {{ review }}
        </div>
    </div>
</template>

<script>
    import { convertDateTimeFormat, getCurrentTimezone } from '@/utils/date'

    const formatDate = (date) => {
        return convertDateTimeFormat(date, 'Etc/UTC', getCurrentTimezone(), 'MMM, D YYYY')
    }

    export default {
        name: 'SocialProofItem',
        props: {
            reviewerName: { type: String, required: true },
            reviewTitle: { type: String, required: true },
            review: { type: String, required: true },
            createdDate: { type: Date, required: true },
            updatedDate: { type: Date, required: false },
        },
        computed: {
            date: function () {
                if (this.updatedDate) {
                    return `Updated ${formatDate(this.updatedDate)}`
                }

                return formatDate(this.createdDate)
            },
        },
    }
</script>

<style scoped>
    .small-text {
        font-size: 0.75rem;
    }

    .star {
        padding-right: 0.25rem;
    }
</style>
