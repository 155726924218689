<template>
    <onboarding-layout
        :loading="loading"
        :error-text="errorText"
        loading-title="Finalizing lookup..."
        data-testid="identity-verification-page"
    >
        <h5>
            <div>Last Step!</div>
            <div class="fw-light">
                We just need to confirm your identity and sign the account agreement
            </div>
        </h5>

        <div class="mt-1 col-12 d-flex justify-content-between">
            <div class="d-flex align-items-center">
                <img
                    src="@/assets/images/pages/marketing/crypto/bullet-one.svg"
                    class="me-2"
                    width="23"
                    alt="Bullet One"
                >
                <p class="mb-0">
                    Take a photo of a government ID
                </p>
            </div>
        </div>
        <div class="mt-2 col-12 d-flex justify-content-between">
            <div class="d-flex align-items-center">
                <img
                    src="@/assets/images/pages/marketing/crypto/bullet-two.svg"
                    class="me-2"
                    width="23"
                    alt="Bullet Two"
                >
                <p class="mb-0">
                    Take a photo of yourself
                </p>
            </div>
        </div>
        <div class="mt-2 col-12 d-flex justify-content-between">
            <div class="d-flex align-items-center">
                <img
                    src="@/assets/images/pages/marketing/crypto/bullet-three.svg"
                    class="me-2"
                    width="23"
                    alt="Bullet Three"
                >
                <p class="mb-0">
                    Digitally sign account agreement
                </p>
            </div>
        </div>

        <form-button
            class="mt-3"
            :submitting="submitting"
            type="button"
            event-name="click_crypto_button_confirm_identity"
            @click="confirmIdentityWithPersona"
        >
            Confirm Identity
        </form-button>
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <help-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormButton from '@/components/base/FormButton'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import originationMixin from '@/mixins/originationMixin'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { PersonaClient, PersonaStatus } from '@/services/persona'
    import { startCase, toLower } from 'lodash'
    import { i18n } from '@/utils/i18n'
    import { logger } from '@/utils/logger'
    import { sharedPagePaths } from '@/routes/sharedRoutes'

    export default {
        name: 'IdentityVerification',
        components: {
            HelpListItem,
            FormButton,
            OnboardingLayout,
        },
        mixins: [originationMixin],
        data: function () {
            return {
                loading: true,
                submitting: false,
                personaClient: null,
                incomeVerificationCompleted: false,
            }
        },
        mounted: async function () {
            appSessionStorage.setItem(localStorageKey.currentFlow, Flows.origination)
            try {
                this.$logEvent('view_crypto_identity_verification')
                this.loading = false
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        computed: {
            applicantFirstName() {
                return startCase(toLower(appSessionStorage.getItem(localStorageKey.firstName)))
            },
        },
        methods: {
            confirmIdentityWithPersona: async function () {
                this.submitting = true
                this.personaClient = new PersonaClient(this.onPersonaReady, this.onPersonaComplete, this.onPersonaCancel, this.onPersonaError)
            },
            onPersonaReady: function () {
                /**
                 * You will always need to open the client in order to access Persona!
                 */
                this.personaClient.personaClient.open()
                this.$logEvent('persona_on_ready')
                this.submitting = false
            },
            onPersonaComplete: async function ({ inquiryId, status, fields }) {
                this.$logEvent('persona_on_complete', { inquiryId, status, fields })
                switch (status) {
                    case PersonaStatus.completed:
                    case PersonaStatus.approved:
                        logger.log(`Successful Persona Identity Verification with status: ${status}, inquiryId: ${inquiryId}`)
                        this.loading = true
                        await this.$router.push(getNextRoute(this.$router))
                        return
                    case PersonaStatus.created:
                    case PersonaStatus.pending:
                    case PersonaStatus.needsReview:
                        logger.log(`Incomplete Persona Identity Verification with status: ${status}, inquiryId: ${inquiryId}`)
                        await this.$router.push({
                            path: sharedPagePaths.THANKS,
                            query: {
                                reason: 'incompleteIdentityVerification',
                            },
                        })
                        return
                    case PersonaStatus.failed:
                    case PersonaStatus.expired:
                    case PersonaStatus.declined:
                        logger.log(`Applicant cannot move forward after Persona Identity Verification with status: ${status}, inquiryId: ${inquiryId}`)
                        await this.$router.push({
                            path: sharedPagePaths.THANKS,
                            query: {
                                reason: 'failedIdentityVerification',
                            },
                        })
                        return
                    default:
                        this.errorText = ApiErrorHandler(new Error(`Persona Identity Verification unknown status: ${status}, inquiryId: ${inquiryId}`))
                        return
                }
            },
            onPersonaCancel: function ({ inquiryId, sessionToken }) {
                this.$logEvent('persona_on_cancel', { inquiryId, sessionToken })
                this.errorText = i18n.tc('identityVerification.cancel')
            },
            onPersonaError: function (error) {
                this.$logEvent('persona_on_error', { error })
                logger.fatal(`Failure during Persona Identity Verification! Error: ${JSON.stringify(error)}`)
                this.errorText = i18n.tc('identityVerification.tryAgain')
            },
        },
    }
</script>
