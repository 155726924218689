<template>
    <div>
        <div v-if="useAutocomplete">
            <form-address-autocomplete-input
                ref="addressAutoCompleteInput"
                @on-change="onChange"
                @on-clear="onClear"
                @use-manual-input="useAutocomplete = false"
                :initial-value="initialValue && initialValue.inputType === 'automatic' ? initialValue.addressComponents : null"
            />
        </div>
        <div v-else>
            <form-address-manual-input
                ref="manualAddressInput"
                @on-change="onChange"
                :initial-value="initialValue"
                :disabled="disabled"
            />
        </div>
    </div>
</template>

<script>
    import FormAddressAutocompleteInput from '@/components/base/FormFieldAddressAutocomplete'
    import FormAddressManualInput from '@/components/base/FormFieldAddressManual'
    import { logger } from '@/utils/logger'

    export default {
        name: 'FormAddress',
        props: {
            /* Expected initial input object structure
                {
                    addressComponents: {
                        addressStreet: string,
                        addressUnit: string,
                        addressCity: string,
                        addressState: string, //2-letter abbr
                        addressPostalCode: string
                        country: 'USA'
                    },
                    query: string (optional, only exists if inputType is automatic)
                    inputType: {'automatic' | 'manual}
                }
             */
            initialValue: { type: Object, required: false },
            initialInputType: {
                type: String,
                default: 'auto',
                validator: function (value) {
                    return ['auto', 'manual'].indexOf(value) !== -1
                },
            },
            disabled: { type: Boolean, default: false },
        },
        components: {
            'form-address-autocomplete-input': FormAddressAutocompleteInput,
            'form-address-manual-input': FormAddressManualInput,
        },
        data() {
            return {
                useAutocomplete: this.initialValue ? this.initialValue.inputType === 'automatic' : true,
            }
        },
        methods: {
            onChange(addressData) {
                if (!addressData) {
                    logger.info('no address data supplied to onChange method')
                    return
                }

                // form-address-autocomplete-input and form-address-manual-input both
                // will emit this event with payload
                /* Expected initial input object structure
                {
                    addressComponents: {
                        addressStreet: string,
                        addressUnit: string,
                        addressCity: string,
                        addressState: string, //2-letter abbr
                        addressPostalCode: string
                        country: 'USA'
                    },
                    query: string (optional, only exists if inputType is automatic)
                    inputType: {'automatic' | 'manual'}
                }
                */
                this.$emit('on-change', addressData)
            },
            onClear() {
                this.$emit('on-clear')
            },
            setError(message) {
                if (this.useAutocomplete) {
                    this.$refs.addressAutoCompleteInput.setError(message)
                }
            },
            loadAddressFormData(addressData) {
                logger.info(`FormFieldAddress load data\n${JSON.stringify(addressData)}`)
                if (addressData.inputType !== 'automatic') {
                    this.$refs.manualAddressInput.loadData(addressData.addressComponents)
                }
            },
            validateAutocompleteAddress() {
                return this.$refs.addressAutoCompleteInput.validateUserInput()
            },
        },
    }
</script>
