<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="computedLoadingTitle"
        :title="title"
        :error-text="errorText"
        step-title="Sign Together"
    >
        <div>
            <list-button
                title="Yes, The Same Time"
                @click="onSubmitSignAtTheSameTime(true)"
            />
            <list-button
                title="No, Different Times"
                @click="onSubmitSignAtTheSameTime(false)"
            />
        </div>
        <template #sidebar>
            <what-is-the-difference-list-item />
            <help-list-item />
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { getSecondaryApplicantInfo, postSecondaryApplicantInfo } from '@/services/offer'
    import { i18n } from '@/utils/i18n'
    import { getNextRoute } from '@/flow/flowController'
    import ListButton from '@/components/ListButton'
    import originationMixin from '@/mixins/originationMixin'
    import { logger } from '@/utils/logger'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import WhatIsTheDifferenceListItem from '@/components/onboarding/WhatIsTheDifferenceListItem'
    import { toTitleCase } from '@/utils/nameFormat'

    export default {
        components: {
            WhatIsTheDifferenceListItem,
            'onboarding-layout': OnboardingLayout,
            'list-button': ListButton,
            'help-list-item': HelpListItem,
        },
        mixins: [originationMixin],
        data: function () {
            return {
                loading: true,
                secondaryApplicantFirstName: '',
            }
        },
        mounted: async function () {
            try {
                const response = await getSecondaryApplicantInfo()
                if (!response.data.success) {
                    logger.log('No secondary applicant found, moving on...')
                    return await this.$router.replace(getNextRoute(this.$router))
                }
                const borrowerCoOwnerName = toTitleCase(response.data.payload.borrowerCoOwnerName)

                this.secondaryApplicantFirstName = borrowerCoOwnerName.split(' ')[0]

                logger.log('Secondary applicant found, sticking around...')
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }

            this.loading = false
            this.$logEvent('view_sign_together')
        },
        computed: {
            title: function () {
                return `Would you and ${this.secondaryApplicantFirstName} like to sign at the same time?`
            },
            computedLoadingTitle: function () {
                return i18n.t('pages.origination.coOwner.loading')
            },
        },
        methods: {
            onSubmitSignAtTheSameTime: async function (value) {
                if (this.submitting) {
                    return
                }

                try {
                    if (value) {
                        this.$logEvent('click_button_secondary_sign_with_primary')
                    } else {
                        this.$logEvent('click_button_secondary_do_not_sign_with_primary')
                    }

                    this.submitting = true

                    await postSecondaryApplicantInfo(value)

                    return await this.$router.push(getNextRoute(this.$router))
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                }

                this.submitting = false
            },
        },
    }
</script>
