<template>
    <onboarding-layout
        :loading="loading"
        :title="title"
        :error-text="errorText"
        step-title="Co-Applicant"
    >
        <div v-if="viewState === ViewState.checkRelationship">
            <list-button
                title="Spouse/Domestic Partner"
                @click="onSpouseVsOtherSubmit('Spouse')"
            />
            <list-button
                title="Other"
                @click="onSpouseVsOtherSubmit('Other')"
            />
        </div>

        <div v-else-if="viewState === ViewState.sorry">
            <img
                src="../../assets/images/global/workinprogress.jpg"
                width="200px"
                alt="Work In Progress"
                class="mx-auto d-block"
            >
            <h5 class="fw-light mt-2">
                It looks like you would need a third signer. We are currently working on automating support for this. We will reach out to you to finalize.
            </h5>
        </div>

        <div v-else>
            <form-container
                id="coOwnerForm"
                ref="form"
                @on-submit="submitCoOwnerInfo"
            >
                <form-field-phone
                    class="mb-2"
                    name="phone"
                    label="Mobile Number"
                    placeholder="Mobile Number"
                    v-model="secondaryApplicantPhoneNumber"
                    validation-rules="required"
                />

                <p class="text-muted mb-2">
                    {{ $t('pages.origination.coOwner.smsAuth') }}
                </p>

                <form-button
                    type="button"
                    label="Continue"
                    :block="true"
                    :submitting="submitting"
                    @click="submitCoOwnerInfo"
                    event-name="click_button_submit_co_owner_form"
                />
            </form-container>
        </div>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { i18n } from '@/utils/i18n'
    import { getNextRoute } from '@/flow/flowController'
    import FormFieldPhone from '@/components/base/FormFieldPhone'
    import ListButton from '@/components/ListButton'
    import FormContainer from '@/components/base/FormContainer'
    import FormButton from '@/components/base/FormButton'
    import originationMixin from '@/mixins/originationMixin'
    import { doesLoanApplicationRequireThirdSignatory } from '@/services/loanApplication'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { getTrustInfo, MaritalStatus, postUpdateApplicantFields } from '@/services/api'
    import { startCase, toLower } from 'lodash'
    import { logger } from '@/utils/logger'

    const ViewState = {
        checkRelationship: 'checkRelationship',
        submitPhoneNumber: 'submitPhoneNumber',
        sorry: 'sorry',
    }

    export default {
        components: {
            'onboarding-layout': OnboardingLayout,
            'form-field-phone': FormFieldPhone,
            'list-button': ListButton,
            'form-container': FormContainer,
            'form-button': FormButton,
        },
        mixins: [originationMixin],
        data: function () {
            return {
                loading: true,
                ViewState: ViewState,
                viewState: ViewState.checkRelationship,
                primaryApplicantPhoneNumber: null,
                secondaryApplicantPhoneNumber: '',
            }
        },
        mounted: async function () {
            if (!this.hasCoApplicant) {
                // If there is no co-applicant we show the individual applicant version of this page instead
                return await this.$router.replace(getNextRoute(this.$router))
            }
            try {
                const trustResponse = await getTrustInfo()
                if (trustResponse.data.payload.success && trustResponse.data.payload.isTrust) {
                    logger.info('Found trust, directly asking for co-applicant phone number')
                    this.viewState = ViewState.submitPhoneNumber
                    this.loading = false
                    this.$logEvent('view_co_owner')
                    return
                }

                const response = await doesLoanApplicationRequireThirdSignatory()
                if (response.data.payload.doesRequireThirdSignatory) {
                    this.viewState = ViewState.sorry
                    this.$logEvent('view_co_owner_sorry')
                }
                this.primaryApplicantPhoneNumber = response.data.payload.phoneNumber

                this.loading = false
                this.$logEvent('view_co_owner')
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        computed: {
            title: function () {
                const primaryFirstName = startCase(toLower(appSessionStorage.getItem(localStorageKey.firstName)))
                const secondaryFirstName = startCase(toLower(appSessionStorage.getItem(localStorageKey.coApplicantFirstName)))

                if (this.viewState === ViewState.checkRelationship) {
                    return i18n.t('pages.coApplicant.coOwner.coApplicantRelationship', { primaryFirstName, secondaryFirstName })
                } else if (this.viewState === ViewState.checkOtherOwners) {
                    return `To know if someone else needs to sign the deed of trust, are either ${primaryFirstName} or ${secondaryFirstName} married?`
                } else if (this.viewState === ViewState.sorry) {
                    return ''
                } else {
                    this.$logEvent('view_co_owner_form')
                    return `Great, what is ${secondaryFirstName}'s mobile number?`
                }
            },
            formattedPrimaryApplicantPhoneNumber: function () {
                return (
                    this.primaryApplicantPhoneNumber.slice(0, 3) +
                    '-' +
                    this.primaryApplicantPhoneNumber.slice(3, 6) +
                    '-' +
                    this.primaryApplicantPhoneNumber.slice(6, this.primaryApplicantPhoneNumber.length)
                )
            },
        },
        methods: {
            onSpouseVsOtherSubmit: function (value) {
                if (value === 'Spouse') {
                    // Married to each other or domestic partners
                    this.viewState = ViewState.submitPhoneNumber
                } else {
                    const primaryMaritalStatus = appSessionStorage.getItem(localStorageKey.applicantMaritalStatus)
                    const secondaryMaritalStatus = appSessionStorage.getItem(localStorageKey.coApplicantMaritalStatus)

                    const MARRIED = MaritalStatus.MARRIED
                    if (primaryMaritalStatus === MARRIED || secondaryMaritalStatus === MARRIED) {
                        // If either primary or secondary are married we cannot continue as a third signer is needed
                        this.viewState = ViewState.sorry
                        this.$logEvent('view_co_owner_sorry')
                    } else {
                        // None is married, awesome!
                        logger.info('Neither primary or secondary are married, asking coOwner phone number')
                        this.viewState = ViewState.submitPhoneNumber
                    }
                }
            },
            submitCoOwnerInfo: async function () {
                if (this.submitting) {
                    return
                }
                this.submitting = true
                this.errorText = ''

                const isValid = await this.$refs.form.$refs.observer.validate()
                if (!isValid) {
                    this.submitting = false
                    return
                }

                try {
                    if (this.secondaryApplicantPhoneNumber === this.primaryApplicantPhoneNumber) {
                        this.errorText = `Spouse's phone number can't be the same as the one we have on file for you (${this.formattedPrimaryApplicantPhoneNumber}). Please enter a different number to continue.`
                    } else {
                        const resp = await postUpdateApplicantFields({ phoneNumber: this.secondaryApplicantPhoneNumber }, true)
                        if (resp.data.success) {
                            await appSessionStorage.setItem(localStorageKey.coApplicantPhoneNumber, this.secondaryApplicantPhoneNumber)
                            return await this.$router.push(getNextRoute(this.$router))
                        }
                        this.errorText = `Invalid US Phone number. Please try again.`
                    }
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                }
                this.submitting = false
            },
        },
    }
</script>
