<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="loadingTitle"
        :error-text="errorText"
    >
        <div class="container pt-6">
            <div class="d-flex flex-column col-12 col-md-6 text-center m-auto">
                <div class="d-none d-md-block text-center pb-4">
                    <img
                        src="@/assets/images/global/aven.svg"
                        class="logo"
                        width="71"
                        alt="Go Home"
                    >
                </div>
                <div class="text-center">
                    <img
                        src="../../assets/images/origination/magnifier.jpg"
                        width="200px"
                        alt="Magnifying Glass"
                    >
                </div>
                <div class="text-center">
                    <h5
                        class="fw-light"
                        v-html="title"
                    />
                </div>
                <div class="d-grid">
                    <button
                        class="btn btn-secondary mt-3"
                        @click="showWebWidget"
                        @keydown.enter="showWebWidget"
                    >
                        Questions? Send Us A Message
                    </button>
                </div>
            </div>
        </div>
    </onboarding-layout>
</template>

<script>
    import { i18n } from '@/utils/i18n'
    import { runFinishedSigningTasks, getSigningUrl } from '@/services/api'
    import { getNextRoute } from '@/flow/flowController'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import zendeskMixin from '@/mixins/zendeskMixin'
    import originationMixin from '@/mixins/originationMixin'
    import OnboardingLayout from '@/layouts/Onboarding'
    import { isIOSSafari } from '@/utils/parseUserAgents'
    import { logger } from '@/utils/logger'

    export default {
        components: {
            OnboardingLayout,
        },
        mixins: [zendeskMixin, originationMixin],
        data() {
            return {
                loading: true,
                loadingTitle: 'Loading Documents...',
                title: i18n.t('signDocuments.title'),
                errorText: '',
            }
        },
        async mounted() {
            this.loading = true
            const applicantFinishedSigning = this.$route.query.event === 'signing_complete'
            try {
                if (applicantFinishedSigning) {
                    this.$logEvent('event_crypto_documents_signed')
                    this.loadingTitle = 'Finalizing Your Account...'
                    await runFinishedSigningTasks()
                    await this.$router.push(getNextRoute(this.$router))
                    return
                } else {
                    const signingUrl = await this.getSigningUrl()
                    if (isIOSSafari()) {
                        window.location.href = signingUrl
                    } else {
                        window.open(signingUrl, '_blank')
                    }
                    this.loadingTitle = 'Opening Document in New Tab... Please Allow Pop-Ups'
                    this.$logEvent('view_crypto_sign_documents')
                }
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        methods: {
            getSigningUrl: async function () {
                try {
                    const response = await getSigningUrl()
                    const { url, hasApplicantSigned } = response.data.payload
                    if (hasApplicantSigned) {
                        logger.log(`Applicant has already signed documents!`)
                        await this.$router.push(getNextRoute(this.$router))
                        return
                    }
                    if (!url) {
                        this.errorText = i18n.t('signDocuments.tryAgain')
                    } else {
                        return url
                    }
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                }
            },
        },
    }
</script>
