<template>
    <div>
        <validation-provider
            ref="provider"
            :mode="validationMode"
            :rules="validationRules"
            :vid="name"
            v-slot="{ errors }"
        >
            <div class="input">
                <div class="input-group">
                    <textarea
                        class="form-control"
                        :rows="rows"
                        :id="name"
                        :name="name"
                        :placeholder="dynamicPlaceholder"
                        :class="{ 'is-invalid': errors[0], 'text-muted': disabled }"
                        :value="value"
                        @input="onInput($event.target)"
                    />
                    <label
                        v-if="label"
                        :for="name"
                    >
                        {{ label }}
                    </label>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    import { formInputMixin } from '@/mixins/formInputMixin'

    export default {
        name: 'FormTextArea',
        mixins: [formInputMixin],
        props: {
            rows: {
                type: Number,
                default: null,
            },
        },
    }
</script>

<style lang="scss">
    @import '../../styles/components/base/formField';
</style>
